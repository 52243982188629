@mixin row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin row-centered {
  @include row;
  justify-content: center;
}

@mixin row-spaced {
  @include row;
  justify-content: space-between;
}

@mixin row-streched {
  @include row;
  justify-content: stretch;
}

@mixin column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
