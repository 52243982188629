@use '../../../styles/index.scss' as styled;

@mixin CreditReportCollapse {
  .ant-collapse > .ant-collapse-item {
    border-bottom: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-collapse-content {
    border-top: 0;
  }

  .ant-collapse-content-box {
    padding-left: 40px;
  }
}

@mixin TableCell {
  .ant-table-cell {
    vertical-align: top;
  }
}

@mixin CreditReportContent {
  @include styled.table;
  @include styled.pagination;
  @include styled.input;
  @include styled.select;
  @include styled.date-picker;
  @include styled.collapse;
  @include styled.form;
  @include styled.radio;
  @include styled.checkbox;

  @include CreditReportCollapse;
  @include TableCell;

  &__collapse {
    max-width: calc(100vw - 26px * 2 - 80px);
    overflow-x: hidden;
  }

  &__footer {
    padding-top: 24px;
  }

  &__add-address-icon {
    color: styled.$red;
    cursor: pointer;
  }
}

.CreditReportContent {
  @include CreditReportContent;
  .ConcernFileRequired {
    color: red;
  }
}

.PanelContentAlignedLeft {
  .ant-collapse-content-box {
    padding-left: 0;
  }
}
