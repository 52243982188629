@use '../../../../../styles/index' as styled;

.OverrideModal {
  @include styled.modal;

  .ant-modal-header {
    padding-bottom: 12px;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: normal;
    color: styled.$gray-background;
    padding-top: 6px;
  }

  &__date {
    padding-top: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  &__form {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__calendar-header {
    @include styled.row-centered;

    &--month {
      margin: 8px;
      font-weight: bold;
    }
  }

  &__footer {
    padding-top: 40px;
  }
}
