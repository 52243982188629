@use '../../../../styles/index.scss' as styled;

.BundleFilesModal {
  @include styled.modal;
  @include styled.select;
  @include styled.input;
  @include styled.labels;

  &__label {
    font-weight: bold;
    color: styled.$gray-text;
    padding-bottom: 14px;
  }

  &__list {
    max-height: 300px;
    min-height: 200px;
    overflow-y: auto;
  }

  @mixin list-item {
    @include styled.row;
    padding: 4px 0;
    background-color: styled.$white;
    cursor: move;
    padding: 10px 0;
    border-bottom: 1px dotted styled.$gray-border;

    &:last-child {
      border-bottom: 0;
    }

    &--title {
      font-weight: bold;
      text-decoration: underline;
      padding-right: 10px;
    }
  }

  &__list-item {
    @include list-item;
    color: styled.$main;
  }

  &__buttons-container {
    padding-top: 40px;
  }

  &__delete-icon {
    font-size: 18px;
    color: styled.$red;
    cursor: pointer;
  }

  &__warning {
    padding-bottom: 24px;
    color: styled.$red;
  }
}
