@use '../../../styles/index.scss' as styled;

.HeaderCell {
  &__title {
    font-weight: bold;
    color: styled.$light-gray-text;
    padding-bottom: 4px;
  }

  &__clickable-text {
    color: styled.$red;
    cursor: pointer;
    text-decoration: underline;
  }
}
