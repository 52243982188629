@use '../../styles/index.scss' as styled;

.RequestForReview {
  @include styled.modal;
  @include styled.input;
  @include styled.date-picker;
  @include styled.select;
  @include styled.tabs;
  @include styled.form;

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    flex: 1;
  }

  &__error {
    margin-top: -26px;
    margin-bottom: 24px;
    color: styled.$red;
  }

  &__summary {
    &--labels {
      font-size: 14px;
      color: styled.$gray-text;
      font-weight: bold;
      padding-bottom: 8px;
    }

    &--row {
      margin-top: 12px;
      border-bottom: 1px solid styled.$light-gray-background;

      .ant-form-item {
        margin-bottom: 12px;
      }
    }

    &--disabled {
      color: styled.$text-disabled;
    }

    &--checkbox-container {
      @include styled.row-centered;
    }
  }

  &__buttons-container {
    margin-top: 40px;
  }

  &__card {
    @include styled.row;
    padding: 6px 0;
    background-color: styled.$white;
    cursor: move;
  }

  &__subtitle {
    padding-bottom: 24px;
  }

  &__warning-message {
    padding-bottom: 24px;
    color: styled.$red;
  }
}
