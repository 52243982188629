@use '../../../../styles/index.scss' as styled;

.ReviewStatusModal {
  @include styled.modal;

  &__label {
    font-weight: bold;
    color: styled.$gray-text;
    padding-bottom: 14px;
  }

  &__buttons-container {
    padding-top: 40px;
  }
}
