@mixin modal {
  .ant-modal-footer {
    padding: 10px 24px;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: 18px;
  }

  .ant-modal-header {
    padding-bottom: 24px;
  }
}
