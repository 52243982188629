@use '../../styles/index.scss' as styled;

.InfoBlock {
  display: flex;
  box-shadow: 0px 3px 15px styled.$box-shadow;
  padding: 20px 25px;

  .ant-progress-success-bg {
    background-color: styled.$green;
  }

  .ant-progress-inner {
    background-color: styled.$disabled-gray-background;
  }

  &__edit-btn {
    color: styled.$red;
    cursor: pointer;
    margin-left: 10px;
  }

  div i {
    color: #ed1a3b;
    margin-left: 6px;
  }
}
