@import '../../styles/variables';

.DebtorProfilePage {
  .ant-collapse-header {
    border-bottom: 2px solid $gray-divider;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  .ant-collapse-content-box {
    padding: 20px 40px 40px !important;
  }
}
