@use '../../../../styles/variables' as vars;

.EmailContentDisplay {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0px 3px 15px #00000024;
}

.title {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  color: vars.$gray-text;
}

.email_element {
  padding-bottom: 10px;
}

.body {
  font-size: 14px;
  padding-bottom: 20px;

  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
}
