@mixin input {
  .ant-input-lg {
    font-size: 14px;
    height: 42px;
  }

  .ant-input-affix-wrapper {
    height: 42px;

    & > input.ant-input {
      height: 32px;
    }
  }

  .ant-input-affix-wrapper-lg {
    padding: 4px 11px;
  }

  .ant-input-search-large .ant-input-search-button {
    height: 42px;
  }
}
