@use '../../../styles/index.scss' as styled;

.AppointmentScheduling {
  @include styled.select;
  @include styled.input;
  @include styled.date-picker;
  @include styled.form;
  @include styled.labels;

  @mixin input-row-container {
    @include styled.row;
    gap: 20px;

    .InputBlock {
      @include styled.column;
      padding-top: 0;
    }
  }

  .ant-picker-calendar {
    padding: 16px;
    border: 1px solid styled.$light-gray;
    margin-bottom: 16px;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    height: 60px;
    width: 60px;
    background-color: styled.$light-blue;
    margin: 4px;
    padding: 0;
    border-top: 1px solid styled.$light-gray;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date {
    background-color: styled.$blue;
  }

  .ant-picker-cell-disabled > .ant-picker-calendar-date {
    background-color: styled.$white !important;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:not(.ant-picker-cell-disabled)
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:not(.ant-picker-cell-disabled):hover
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value {
    color: styled.$white;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value {
    color: styled.$text-disabled;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    margin-right: 6px;
  }

  &__calendar-header {
    @include styled.row-centered;

    &--month {
      margin: 8px;
      font-weight: bold;
    }
  }

  &__location {
    position: relative;

    .ant-form-item-label {
      @include styled.row-spaced;

      label {
        @include styled.row-spaced;
        flex: 1;
      }
    }

    &--icon {
      @include styled.row-centered;
      position: absolute;
      z-index: 2;
      background-color: styled.$white;
      right: 1px;
      top: 0;
      width: 32px;
      height: 32px;
      margin-top: 5px;
      cursor: pointer;
    }

    &--popover {
      @include styled.row-spaced;
      flex: 1;
    }

    &--popover-icon {
      color: styled.$red;
    }
  }

  &__container {
    margin-top: 40px;
  }

  &__form {
    &--inputs-container {
      @include input-row-container;
    }

    &--buttons-container {
      @include input-row-container;
      padding-top: 24px;
      align-items: flex-end;
    }

    &--appointments {
      border: 1px solid styled.$light-gray;
      background-color: styled.$light-gray-background;
      max-height: 266px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &--appointment-item {
      background: styled.$light-gray-background;
      border-bottom: 1px solid styled.$light-gray;
      padding: 4px;
    }

    @mixin option {
      @include styled.row-centered;
      justify-content: flex-start;
      flex: 1;
      padding: 4px 8px;
    }

    &--option-time {
      @include option;
      font-weight: bold;
    }

    &--option-button {
      @include option;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: styled.$gray-border;
        color: styled.$main;
      }

      &-active,
      &-active:hover {
        background-color: styled.$red;
        color: styled.$white;
      }

      i {
        margin-right: 6px;
      }
    }

    &--note-container {
      @include styled.row;
      cursor: pointer;

      svg {
        fill: styled.$red;
        margin-left: 6px;
      }
    }
  }

  &__calendar {
    max-width: 590px;
    min-width: 480px;
  }
}
