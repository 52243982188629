@use '../../../../styles/variables' as vars;

.SearchAscend {
  display: flex;
  flex-direction: column;
  position: relative;
}

.date_of_insolvency_datepicker {
  display: flex;

  [class~='ant-picker'] {
    height: 42px;
    flex: auto;
    max-width: 100%;
  }
}

.number_of_fields_message {
  font-size: 12px;
  color: vars.$gray-text;
  padding-bottom: 10px;
}

.divider {
  margin: 0 0 14px;
}

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.dynamic_fields_section {
  display: flex;
  flex-direction: column;

  .number_of_fields_message {
    padding-left: 10px;
  }
}

.dynamic_fields_container {
  display: flex;
}
