@use '../../../../../styles/index.scss' as styled;

.AdminInfo-GeneralForm {
  @include styled.form;
  @include styled.labels;
  @include styled.input;
  @include styled.select;
}

.extra-content div i {
  color: #ed1a3b;
}
