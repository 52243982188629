@use '../../../styles/index.scss' as styled;

.FileRow {
  @include styled.row;
  background-color: styled.$white;
  margin-top: 12px;
  padding-right: 10px;
  border-bottom: 1px dotted styled.$gray-border;

  &:last-child {
    border-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &__title {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  &__item {
    margin: 0 10px;
  }
}
