@use '../../styles/variables' as vars;

.InfoBubbleWrapper {
  display: inline-block;
  border-radius: 4px;
  color: vars.$white;
  padding: 1px 4px;
}

.InfoBubble {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.icon {
  font-size: 8px;
  padding-right: 2px;
  position: relative;
  // top: 2px;
}

.directional .icon {
  top: 2px;
}
