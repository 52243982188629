@use '../../../styles/index.scss' as styled;

.UserManagement {
  @include styled.input;
  @include styled.table;
  @include styled.pagination;

  .ant-input-search-large .ant-input-search-button {
    height: 42px;
  }

  &__modal {
    @include styled.modal;
    @include styled.labels;
    @include styled.form;
    @include styled.checkbox;
  }

  &__table {
    padding-top: 24px;
  }

  &__edit-icon {
    font-size: 16px;
    color: styled.$red;
    cursor: pointer;
  }

  .ant-message-error {
    span {
      white-space: pre-wrap;
    }
  }
}
