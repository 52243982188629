@use './variables' as variables;
@use './basic.scss' as basic;

@mixin checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: variables.$red;
    border-color: variables.$red;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: variables.$field-disabled;
    border-color: variables.$field-disabled;
  }
}
