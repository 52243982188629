.NewIncomeAndExpensePage {
  .divider {
    margin: 0 0 10px 0;
  }
}

.label_n_img_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: auto;
  height: 50%;
  width: 220px;
  align-items: center;
  text-align: center;
}
