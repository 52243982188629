@use '../../../styles/index.scss' as styled;

.FileAnalysis {
  @include styled.tabs-gray;
  @include styled.collapse;
  @include styled.radio;
  @include styled.checkbox;

  &__data-entry-container {
    padding-top: 20px;
  }

  &__form {
    @include styled.input;
    @include styled.labels;

    &--list-item {
      border-bottom: 1px dotted styled.$input-border;

      .ant-form-item {
        margin-bottom: 14px;
        margin-top: 14px;
      }

      &:nth-child(5) {
        border-bottom: none;
      }
    }

    &--total-payments {
      font-size: 30px;
    }

    &--available-net-income {
      font-size: 30px;
    }

    &--total-assets,
    &--total-incomes {
      font-size: 30px;
      color: styled.$green;
      font-weight: 700;
    }

    &--total-debts,
    &--total-expenses {
      font-size: 30px;
      color: styled.$red;
      font-weight: 700;
    }

    &--debts-creditors-list,
    &--incomes-expenses-list {
      padding-bottom: 42px;

      .ant-form-item-label {
        padding: 0;
      }

      .ant-form-item-control-input {
        min-height: 0;
      }

      .ant-form-item {
        margin-bottom: 8px;
      }
    }

    &--total-divider {
      margin: 12px 0;
    }

    &--tooltip-icon {
      color: styled.$red;
      font-size: 16px;
      vertical-align: middle;
      font-weight: bold;
      cursor: pointer;
    }

    &--add-icon {
      color: styled.$red;
      gap: 6px;
      font-weight: 600;
      cursor: pointer;
      padding-top: 16px;
    }

    &--delete-icon {
      color: styled.$red;
      font-size: 18px;
      cursor: pointer;
    }

    &--label-with-checkbox {
      .ant-form-item-label label {
        @include styled.row;
        font-weight: 400;
      }

      .ant-form-item-label label:not(.ant-checkbox-wrapper) {
        font-weight: 700;
      }
    }

    .ant-tabs-content-holder {
      padding: 0;
      background-color: styled.$white;
    }
  }

  &__results {
    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content-holder {
      padding: 0;
      background-color: styled.$light-gray-background;
    }

    @mixin row {
      text-align: right;
      padding: 12px 0;
    }

    &--row-fees {
      @include row;
      padding: 6px 0;
    }

    &--row {
      @include row;
      border-bottom: 1px solid styled.$gray-divider;
    }

    &--row-prior-total {
      @include row;
      border-bottom: 1px solid styled.$main;
    }

    &--row-percentage {
      @include row;
      margin-top: -20px;
      font-size: 12px;
    }

    &--row-title {
      text-align: left;
    }

    &--cell-total {
      font-weight: bold;
    }

    @mixin results-container {
      min-height: 100vh;
      overflow-y: auto;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &--container {
      @include results-container;
    }

    &--container-striped {
      @include results-container;
      padding-top: 24px;

      &--header {
        background-color: styled.$white;
        padding: 10px;
        border: 1px solid styled.$light-gray-background;
      }
    }

    &--form48-container {
      @include results-container;
      padding: 24px;
    }

    &--fees-container {
      @include results-container;
      padding-bottom: 0;
    }

    &--fees-subcontainer {
      border-bottom: 50px solid styled.$white;
      padding: 24px;

      &:first-child {
        padding-top: 0;
      }
    }

    &--calculations-container {
      @include results-container;
      padding: 40px 24px;
    }

    &--calculations-subcontainer {
      text-indent: 20px;
      padding-bottom: 40px;
    }

    &--surplus-container {
      padding: 24px;
      min-height: 100vh;
    }
  }

  &__results--container-striped &__results--row:nth-child(odd) {
    background-color: styled.$lighter-cell-background;
    padding: 0 20px;
    text-align: center;
  }

  &__results--container-striped &__results--row:nth-child(even) {
    background-color: styled.$white;
    padding: 0 20px;
    text-align: center;
  }

  &__scrollable {
    @mixin row {
      padding-top: 6px;
      padding-bottom: 6px;
      white-space: pre;
    }

    &--column {
      text-align: right;
    }

    &--name-column {
      text-align: left;
    }

    &--total-column {
      padding-right: 6px;
      text-align: right;
    }

    &--bordered-column {
      border-right: 1px solid styled.$gray-divider;
    }

    &--row {
      @include row;

      &:first-child {
        font-weight: bold;
        border-bottom: 1px solid styled.$gray-divider;
        text-indent: 12px;
      }

      &:last-child {
        border-top: 1px solid styled.$main;
        font-weight: bold;
        padding: 12px 0;
      }
    }

    &--content {
      display: flex;
      flex-direction: row;
      text-indent: 0;
    }
  }

  .ant-input-number,
  .ant-input-number-affix-wrapper {
    width: 100%;
  }
}
