.DynamicInfoBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px 25px;
  background-color: white;
  box-shadow: 0px 3px 15px #00000024;
}

.record_row:not(:first-child) {
  margin-top: 3px;
}
