@use '../../styles/index' as styled;

.PageSubtitle {
  margin: 25px 0;

  &__title {
    font-size: 18px;
    font-weight: bold;
  }
}
