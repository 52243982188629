@use '../../../styles/variables' as vars;

.CustomDropdownButton {
  display: inline-block;
  height: 42px;
  line-height: 42px;

  .ant-btn {
    height: 42px;
    color: vars.$bright-text;
    border: 0;
    // border-radius: 0;
    // background-color: styled.$light-gray-background;
    border-color: unset;
    font-weight: bold;
    // min-width: 180px;
  }

  .ant-btn:first-child {
    line-height: 42px;
    padding: 0 24px;
    min-width: 150px;
  }

  .ant-btn.ant-dropdown-trigger {
    width: 30px;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-dropdown-button.Button__primary .ant-btn[disabled],
  .ant-dropdown-button.Button__success .ant-btn[disabled] {
    color: vars.$bright-text;
    background-color: vars.$disabled-gray-background;
  }

  .Button {
    &__small button.ant-btn:focus {
      color: vars.$dark-text;
    }

    &__no-padding .ant-btn:first-child {
      padding: 0 0;
    }
    &__small .ant-btn:first-child {
      padding: 0 15px;
    }
    &__medium .ant-btn:first-child {
      padding: 0 30px;
    }
    &__large .ant-btn:first-child {
      padding: 0 51px;
    }

    &__height-short .ant-btn {
      height: 32px;
      line-height: 32px;
    }

    &__height-standard .ant-btn {
      height: 42px;
      line-height: 42px;
    }

    &__height-tall .ant-btn {
      height: 52px;
      line-height: 52px;
    }

    &__height-short .ant-btn .anticon,
    &__height-standard .ant-btn .anticon,
    &__height-tall .ant-btn .anticon {
      vertical-align: -0.25em;
    }

    &__narrow .ant-btn {
      min-width: unset;
    }

    &__primary .ant-btn {
      background-color: vars.$red;
    }
    &__primary .ant-btn:hover,
    &__primary .ant-btn:focus {
      color: vars.$bright-text;
      background: vars.$red-faded;
      border-color: vars.$red-faded;
    }

    &__secondary .ant-btn {
      background-color: vars.$orange;
    }
    &__secondary .ant-btn:hover,
    &__secondary .ant-btn:focus {
      color: vars.$bright-text;
      background: vars.$orange-faded;
      border-color: vars.$orange-faded;
    }

    &__cancel .ant-btn,
    &__darkgray .ant-btn {
      background-color: vars.$gray-background;
    }
    &__cancel .ant-btn:hover,
    &__cancel .ant-btn:focus,
    &__darkgray .ant-btn:hover,
    &__darkgray .ant-btn:focus {
      color: vars.$bright-text;
      background: vars.$gray-background-faded;
      border-color: vars.$gray-background-faded;
    }

    &__special .ant-btn {
      background-color: vars.$special-blue;
    }
    &__special .ant-btn:hover,
    &__special .ant-btn:focus {
      color: vars.$bright-text;
      background: vars.$special-blue-faded;
      border-color: vars.$special-blue-faded;
    }

    &__success .ant-btn {
      background-color: vars.$green;
    }
    &__success .ant-btn:hover,
    &__success .ant-btn:focus {
      color: vars.$bright-text;
      background: vars.$green-faded;
      border-color: vars.$green-faded;
    }
  }

  //   button.ant-btn:focus &__small.ant-btn {
  //     color: vars.$dark-text;
  //   }

  //   &__small.ant-btn {
  //     padding: 0 15px;
  //   }
  //   &__medium.ant-btn {
  //     padding: 0 30px;
  //   }
  //   &__large.ant-btn {
  //     padding: 0 51px;
  //   }

  //   &__height-short.ant-btn {
  //     height: 32px;
  //     line-height: 32px;
  //   }

  //   &__height-tall.ant-btn {
  //     height: 52px;
  //     line-height: 52px;
  //   }

  //   &__height-short.ant-btn .anticon,
  //   &__height-tall.ant-btn .anticon {
  //     vertical-align: -0.25em;
  //   }

  //   &__narrow.ant-btn {
  //     min-width: unset;
  //   }

  //   &__primary {
  //     background-color: vars.$red;
  //   }
  //   &__primary.ant-btn:hover,
  //   &__primary.ant-btn:focus {
  //     color: vars.$bright-text;
  //     background: vars.$red-faded;
  //     border-color: vars.$red-faded;
  //   }

  //   &__secondary {
  //     background-color: vars.$orange;
  //   }
  //   &__secondary.ant-btn:hover,
  //   &__secondary.ant-btn:focus {
  //     color: vars.$bright-text;
  //     background: vars.$orange-faded;
  //     border-color: vars.$orange-faded;
  //   }

  //   &__cancel, &__darkgray {
  //     background-color: vars.$gray-background;
  //   }
  //   &__cancel.ant-btn:hover,
  //   &__cancel.ant-btn:focus,
  //   &__darkgray.ant-btn:hover,
  //   &__darkgray.ant-btn:focus {
  //     color: vars.$bright-text;
  //     background: vars.$gray-background-faded;
  //     border-color: vars.$gray-background-faded;
  //   }

  //   &__special {
  //     background-color: vars.$special-blue;
  //   }
  //   &__special.ant-btn:hover,
  //   &__special.ant-btn:focus {
  //     color: vars.$bright-text;
  //     background: vars.$special-blue-faded;
  //     border-color: vars.$special-blue-faded;
  //   }

  &__success {
    background-color: vars.$green;
  }
  &__success.ant-btn:hover,
  &__success.ant-btn:focus {
    color: vars.$bright-text;
    background: vars.$green-faded;
    border-color: vars.$green-faded;
  }
}
