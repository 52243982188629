@use '../../../../../../styles/index.scss' as styled;

.CounsellingViews {
  @include styled.select;
  @include styled.form;

  &__section {
    padding-bottom: 40px;
  }

  &__section-header {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 14px;

    &--details {
      font-weight: normal;
    }
  }

  &__session {
    border-bottom: 1px dotted styled.$gray-border;
    padding: 12px 0;

    &--title {
      font-weight: bold;
      color: styled.$gray-text;
      margin-right: 6px;
    }

    &--status-value {
      font-weight: bold;
      color: styled.$gray-background;
      text-decoration: underline;
      cursor: pointer;
    }

    &--appointment {
      font-weight: bold;
    }
  }

  &__add-session-container {
    padding-top: 24px;
    font-size: 12px;
    font-weight: bold;
    color: styled.$red;
    cursor: pointer;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: red;
  }

  &__data-row {
    padding: 6px 0;
  }

  &__add-icon {
    font-size: 9px;
    font-weight: 700;
    padding: 0 2px;
    border: 1px solid styled.$red;
    border-radius: 2px;
  }
}
