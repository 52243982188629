@use '../../../styles/index.scss' as styled;

.Custom_FormInput {
  [class~='ant-form-item-label'] > label {
    display: flex;
    color: styled.$gray-text;
    font-size: 14px;
    font-weight: bold;

    [required][data-element-type='unique']::after {
      display: inline-block;
      margin-right: 4px;
      margin-left: 4px;
      color: styled.$red;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      font-weight: bold;
      content: '*';
    }

    // [class~="label-wrapper"][class~="required"] [class~="label-text"]::after {
    //     display: inline-block;
    //     margin-right: 4px;
    //     margin-left: 4px;
    //     color: styled.$red;
    //     font-size: 16px;
    //     font-family: SimSun, sans-serif;
    //     line-height: 1;
    //     font-weight: bold;
    //     content: '*';
    // }
  }

  [class~='readonly-field'] [class~='ant-input'],
  [class~='readonly-field'] [class~='ant-select'] {
    cursor: not-allowed;
  }

  [class~='readonly-field'] [class~='ant-select'] [class~='ant-select-selection-item'],
  [class~='readonly-field'] [class~='ant-input-affix-wrapper-readonly'] [class~='ant-input'] {
    color: styled.$gray-text;
  }

  [class~='readonly-field'] [class~='DatePicker_Custom'] [class~='ant-picker'][class~='ant-picker-disabled'] {
    background: transparent;
  }

  [class~='readonly-field'] [class~='ant-input']:hover,
  [class~='readonly-field'] [class~='DatePicker_Custom'] [class~='ant-picker']:hover,
  [class~='readonly-field']
    [class~='ant-select']:not([class~='ant-select-disabled']):hover
    [class~='ant-select-selector'],
  [class~='readonly-field'] [class~='ant-input-affix-wrapper']:not([class~='ant-input-affix-wrapper-disabled']):hover {
    border-color: styled.$input-border;
    box-shadow: none;
  }

  [class~='ant-form-item-has-error'] [class~='DatePicker_Custom'] [class~='ant-picker'] {
    border-color: #ff4d4f !important;
  }
}
