@use '../styles/variables' as variables;

@mixin pagination {
  .ant-pagination {
    font-size: 14px;
    color: variables.$main;
  }

  .ant-table-pagination-left,
  .ant-table-pagination-right {
    align-items: center;
  }

  .ant-list-pagination {
    text-align: left;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    outline: none;
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    line-height: 22px;

    &:hover {
      border-color: variables.$gray-divider;
    }

    &:focus-visible,
    &:hover {
      a {
        color: variables.$main;
      }
    }
  }

  .ant-pagination-item-active {
    border-color: variables.$red;
    background-color: variables.$red;

    &,
    &:focus-visible,
    &:hover {
      a {
        color: variables.$white;
      }
    }
  }
}
