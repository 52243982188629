@use '../../styles/index.scss' as styled;

.TabSystem_horizontal_tabs {
  [class~='ant-tabs-tab'][class~='tab-hidden'] {
    display: none;
  }
  [class~='ant-tabs-tab'][class~='tab-disabled'],
  [class~='ant-tabs-tab'][class~='tab-disabled']:hover,
  [class~='ant-tabs-tab'][class~='tab-disabled']:focus,
  [class~='ant-tabs-tab'][class~='tab-disabled']:active,
  [class~='ant-tabs-tab'][class~='tab-disabled'] [class~='ant-tabs-tab-btn']:focus,
  [class~='ant-tabs-tab'][class~='tab-disabled'] [class~='ant-tabs-tab-btn']:active {
    color: styled.$text-disabled;
    cursor: default;
  }
}

.TabSystem_vertical_tabs {
  [class~='TabsLeft__subtab--container'] [class~='tab-hidden'] {
    display: none;
  }
}
