@use '../../../styles/index.scss' as styled;

.DebtorProfileContent {
  @include styled.form;
  @include styled.labels;
  @include styled.collapse;
  @include styled.select;
  @include styled.input;
  @include styled.date-picker;
  @include styled.checkbox;

  margin-top: 40px;

  .ant-form-item-label > label {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .ant-picker-input > input {
    font-size: 14px !important;
  }

  &__panel-header {
    @include styled.row;

    &--icon {
      color: styled.$red;
      margin-left: 8px;
    }
  }

  &__checkbox {
    flex-direction: row-reverse;
    font-weight: normal;
  }

  &__form-item {
    &--extra-label {
      & .ant-form-item-label > label {
        display: flex;
        flex: 1;
        flex-direction: row;
      }
    }

    &--label-required::after {
      margin-left: 4px;
      color: #ed1a3b;
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
      font-family: SimSun, sans-serif;
      content: '*';
    }

    &--label {
      flex: 1;
      display: flex;
    }

    &--label-hidden {
      & .ant-form-item-label > label {
        visibility: hidden;
      }
    }
  }

  &__link-button {
    color: styled.$red;
    cursor: pointer;
    margin-top: -10px;
    text-align: right;
  }

  &__icon {
    color: styled.$red;
    cursor: pointer;
    font-size: 18px;
  }

  &__footer {
    padding-top: 40px;
  }
}
