@use '../../styles/index.scss' as styled;

.GoogleMaps {
  @include styled.modal;

  .ant-modal-body {
    padding: 24px;
  }

  &__marker {
    font-size: 13px;
    font-weight: bold;

    span {
      font-size: 11px;
      font-weight: normal;
    }

    &--working-hours {
      padding-top: 12px;
      font-weight: bold;
    }
  }
}
