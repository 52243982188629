@use '../../styles/index.scss' as styled;

.MarketingReport {
  @include styled.table;
  @include styled.pagination;
  @include styled.form;
  @include styled.date-picker;
  @include styled.radio;

  &__clickable-cell {
    cursor: pointer;
  }

  &__footer {
    padding-top: 40px;
  }
}
