@use '../../styles/index.scss' as styled;

.SearchLocationInput {
  position: relative;

  &__input {
    position: relative;

    input[type='text'] {
      box-sizing: border-box;
      width: 100%;

      color: styled.$main;
      font-size: 14px;
      line-height: 1.5715;
      background-color: styled.$white;
      border: 1px solid styled.$input-border;
      border-radius: 2px;
      transition: all 0.3s;

      &:focus {
        border-color: styled.$focus-blue;
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        border-right-width: 1px !important;
        outline: 0;
      }

      &:disabled {
        background-color: styled.$field-disabled;
        color: styled.$text-disabled;
      }
    }

    &--default {
      input[type='text'] {
        padding: 4px 11px;
      }
    }

    &--large {
      input[type='text'] {
        padding: 9px 11px;
      }
    }

    &--icon {
      @include styled.row-centered;
      position: absolute;
      width: 32px;
      height: 32px;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  &__dropdown {
    @include styled.column;
    position: absolute;

    top: 32px;
    background-color: styled.$white;
    border-radius: 2px;
    box-shadow: 0px 3px 15px styled.$box-shadow;
    padding: 12px 0;
    color: styled.$main;
    z-index: 2;

    &--item {
      padding: 6px 12px;
      flex: 1;
      cursor: pointer;

      &:hover {
        background-color: styled.$light-gray-background;
      }
    }
  }
}

.ant-form-item-has-error {
  .SearchLocationInput {
    input[type='text'] {
      border: 1px solid styled.$red;
    }
  }
}
