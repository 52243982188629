@use '../../../styles/index.scss' as styled;

.ApplicationOverviewContent {
  @include styled.tabs;

  & > .ant-collapse-header {
    border-bottom: 2px solid styled.$gray-divider;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  @mixin extra-inner-container {
    @include styled.row-centered;
    gap: 30px;
    margin-top: 3px;
    margin-right: 4px;
  }

  .ant-collapse-header &__extra-inner-container {
    @include extra-inner-container;
    opacity: 0;
  }

  .ant-collapse-header:hover &__extra-inner-container {
    @include extra-inner-container;
    opacity: 1;
  }

  & > .ant-collapse-content-box {
    padding: 0 0 20px 40px !important;
  }

  &__tabs > .ant-tabs-nav {
    @include styled.tabs;

    .ant-tabs-tab:nth-child(5) {
      display: none;
    }
  }

  &__tab-hidden {
    .ant-tabs-tab {
      display: none;
    }
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    border-left: 0;
    padding-top: 24px;
  }

  &__space {
    .ant-popover-inner-content {
      padding: 16px 0;
    }

    .ant-space-item {
      .ant-btn {
        background-color: styled.$light-gray-background;
        border: none;
        color: styled.$main;
        height: 50px;
        padding: 18px 12px;
        display: flex;
        line-height: 18px;
        font-weight: bold;

        &:hover,
        &:focus {
          color: styled.$main;
        }
      }
    }

    .ant-popover-open {
      .ApplicationOverviewPage__chevron-icon-up {
        display: none;
      }

      .ApplicationOverviewPage__chevron-icon-down {
        display: inline-block;
      }
    }

    button {
      overflow: hidden;
    }
  }

  &__icon {
    margin-right: 10px;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
  }

  &__icon--appform {
    font-size: 18px;
    margin-left: 30px;
    color: styled.$red;
    transform: rotate(-90deg);
    font-weight: normal;
  }

  &__panel-header {
    font-weight: bold;

    span {
      color: styled.$red;
      font-size: 14px;
      margin-left: 10px;
    }
  }

  &__extra-container {
    @include styled.row-centered;
    flex-shrink: 0;
  }

  &__extra-btn {
    font-size: 12px;
    font-weight: 400;
  }

  &__extra-btn-add {
    color: styled.$red;
    font-weight: 600;
  }

  &__collapse {
    @include styled.collapse;
    .ant-collapse-header {
      border-bottom: 2px solid styled.$gray-divider;
    }
  }
}

.ant-popover-inner-content {
  padding: 6px 24px;
  min-width: 300px;
}
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomLeft {
  padding-top: 0;
}
