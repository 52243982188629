@use '../../styles/index.scss' as styled;

.SchedulingComponent {
  @include styled.input;
  @include styled.date-picker;
  @include styled.labels;
  @include styled.form;
  @include styled.select;

  &__summary {
    // margin-bottom: 24px;
    border: 1px solid styled.$light-gray;
    padding: 12px;
  }

  &__date-picker {
    .ant-picker {
      width: 100%;
      height: 44px;
    }
  }

  &__location {
    & .ant-form-item-label > label {
      display: flex;
      flex: 1;
    }

    & .ant-select-arrow {
      top: 0;
      right: 16px;
      width: 12px;
      height: 38px;
      margin-top: 0;
      display: flex;
      flex: 1;
      align-items: center;

      & .anticon {
        color: styled.$gray-text;
      }
    }

    &--popover {
      flex: 1;
      display: flex;
    }

    &--popover-icon {
      color: styled.$red;
      font-weight: normal;
      cursor: pointer;
    }
  }

  &__agents {
    & .ant-form-item-label > label {
      display: flex;
      flex: 1;
    }

    &--label {
      flex: 1;
      display: flex;
    }

    &--popover {
      flex: 1;
      display: flex;
      color: styled.$main;
      font-weight: normal;
    }

    &--popover-icon {
      color: styled.$red;
      cursor: pointer;
    }
  }

  &__label {
    &--required::after {
      display: inline-block;
      margin-left: 4px;
      color: styled.$red;
      font-size: 14px;
      line-height: 1;
      content: '*';
    }
  }
}
