@use '../../../../../styles/index.scss' as styled;

.EditableTable {
  @include styled.table;

  &__editable-cell {
    position: relative;
  }

  &__editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  &__editable-row:hover &__editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid styled.$input-border;
    border-radius: 2px;
  }

  [data-theme='dark'] &__editable-row:hover &__editable-cell-value-wrap {
    border: 1px solid styled.$cell-value-wrap;
  }

  &__buttons-container {
    padding-top: 24px;
  }

  &__form-item {
    margin: 0;

    .ant-form-item-explain {
      min-height: 0;
    }
  }
}
