@use '../../styles/index.scss' as styled;

.SimilarRecords {
  @include styled.table;
  @include styled.pagination;

  &__item {
    &--highlighted {
      background-color: styled.$light-red !important;
      font-weight: bold !important;
    }
  }
}
