@use '../../styles/index.scss' as styled;

.NewProspectPage {
  @include styled.form;
  @include styled.input;
  @include styled.select;

  margin-top: 45px;
  &__modal {
    @include styled.modal;

    &--message {
      padding: 12px 0;
    }
  }
}
