@use '../../styles/index.scss' as styled;

.OnDropUploader {
  &__files-list {
    padding-top: 24px;
  }
  &__files-list__headers,
  &__file-row__static-value {
    color: styled.$gray-text;
    font-weight: 600;
  }
  &__file-row {
    padding: 8px 0;
    border-bottom: 1px dotted styled.$gray-divider;
  }

  &__file-name {
    font-weight: bold;
    text-decoration: underline;
  }

  &__dragger {
    // @include styled.column;
    align-items: center;
    padding-bottom: 24px;
    background-color: styled.$light-gray-background;
    border: 1px solid styled.$light-gray;
    cursor: pointer;
    max-height: 200px;

    &--title {
      font-weight: bold;
    }

    i {
      color: styled.$red;
      font-size: 40px;
    }
  }

  &__icon {
    font-size: 18px;
    color: styled.$red;
    cursor: pointer;
  }
}
