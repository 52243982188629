@use '../../../../../styles/index.scss' as styled;

.SubTab {
  @include styled.row-spaced;
  //align-items: start;
  align-items: stretch;
  gap: 8px;
  margin: 0;
  padding: 8px 10px;
  border-bottom: 1px solid styled.$light-gray;
  cursor: pointer;

  &:last-child {
    border: none;
  }

  &__active {
    background-color: styled.$subtab-active-background;
    margin: 0;
    padding: 8px 10px;
    cursor: default;
  }

  &:not(&__active) {
    &:hover {
      background-color: styled.$light-gray-background;
    }
  }

  &__inner-container {
    @include styled.column;
    justify-content: center;
    padding-right: 10px;
  }

  &__title {
    font-weight: bold;
  }

  &__subtitle {
    color: styled.$gray-text !important;
    font-weight: normal;
  }

  &__icon {
    font-size: 18px;
  }
}
