@use '../../../../../../../styles/index.scss' as styled;

.PADViewModal {
  @include styled.modal;

  .ant-modal-body {
    padding-top: 0;
  }

  &__item {
    padding: 12px 0;
    border-bottom: 1px dotted styled.$gray-divider;
  }

  &__footer {
    padding-top: 24px;
  }
}
