@use '../../styles/index.scss' as styled;

.ActionMenu {
  font-size: 14px;

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu-item-selected {
    color: styled.$red;
    background-color: styled.$light-gray-background;
  }

  .ant-menu-item,
  .anticon {
    svg {
      font-size: 20px;
      color: styled.$red;
    }
  }

  .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
  }
}
