@use '../../../styles/index.scss' as styled;

.Custom_Label {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;

  [class~='label-text-wrapper'],
  [class~='label-text'] {
    flex: 1 0 0;
  }

  [class~='label-text-wrapper'],
  [class~='extra-content'] {
    display: flex;
    align-items: flex-end;
  }

  [class~='extra-content'] {
    color: styled.$dark-text;
  }

  &[class~='required'] [class~='label-text']::after {
    display: inline-block;
    margin-right: 4px;
    margin-left: 4px;
    color: styled.$red;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: bold;
    content: '*';
  }

  [class~='label-text-wrapper'] [class~='label-text-icon'] {
    font-size: 16px;
    margin-left: 5px;
    color: styled.$red;
    font-weight: normal;
    line-height: 1.2em;
  }
}
