@use '../../../../styles/index' as styled;

.EmailTable {
  @include styled.table;

  &__table {
    &-empty {
      @include styled.table-empty;
    }
  }

  .ant-table-cell {
    i {
      color: styled.$red;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
