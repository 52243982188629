@use '../../styles/index.scss' as styled;

.FollowUp {
  @include styled.form;
  @include styled.modal;
  @include styled.date-picker;

  .ant-modal-body {
    padding-top: 12px;
  }
}
