@import '../../../../styles/variables';

.NoteEditor {
  &__text-area-icons-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: #ed1a3a;
    font-size: 18px;
    position: absolute;
    bottom: 8px;
    right: 0;

    i {
      margin-right: 15px;
      cursor: pointer;
    }
  }

  &__text-area {
    position: relative;
    border-radius: 9px;
    resize: none;

    &:hover {
      border-color: #dadada;
    }
  }

  &__avatar {
    background-color: #667b91;
    font-size: 14px !important;
  }
}
