.PreviewFileArray {
  .file_preview_header {
    display: flex;

    & > *:not(:first-child) {
      margin-left: 10px;
    }

    .file_preview_actions {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .file_preview_actions .action:not(:first-child) {
      margin-left: 20px;
    }

    .file_preview_actions .extra_actions {
      display: flex;
      align-items: center;

      > * {
        margin-left: 20px;
      }
    }
  }

  &.component_as_page .file_preview_header {
    padding: 15px;
  }

  .preview_file_container {
    [class~='ant-spin-container'] > [class~='ant-row-space-between'] {
      display: none;
    }

    [class~='ant-spin-container'] [class~='PreviewFile__preview-container'] {
      margin-left: 0;
    }
  }
}
