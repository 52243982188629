@use '../../../../styles/index.scss' as styled;

.ApplicationFormTab {
  @include styled.form;
  @include styled.labels;
  @include styled.input;
  @include styled.select;
  @include styled.date-picker;
  @include styled.radio;
  @include styled.collapse;

  &__supporting-documents {
    @include styled.checkbox;

    .ant-table-tbody > tr:not(.ApplicationFormTab__supporting-documents--no-details-row):not(:first-child) > td {
      border-top: 1.5px solid styled.$gray-divider !important;
    }

    .ant-table-tbody > tr.ApplicationFormTab__supporting-documents--no-details-row > td:not(:first-child) {
      border-top: 1.5px dotted styled.$gray-divider !important;
    }

    .ant-table-tbody > tr:last-child > td {
      border-bottom: 1.5px dotted styled.$gray-divider !important;
    }

    .ant-table-tbody > tr > td {
      cursor: default;
    }

    .ant-table-tbody > tr:nth-child(odd).ant-table-row:hover > td:not(:first-child),
    .ant-table-tbody > tr:nth-child(even).ant-table-row:hover > td:not(:first-child) {
      background-color: styled.$white !important;
    }

    .ant-table-tbody > tr:nth-child(odd).ant-table-row:hover > td:first-child,
    .ant-table-tbody > tr:nth-child(even).ant-table-row:hover > td:first-child {
      background-color: styled.$light-gray-background !important;
    }

    .ant-table-tbody > tr > td:first-child {
      background-color: styled.$light-gray-background;
      vertical-align: top;
    }

    @mixin link {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
    }

    &--uploaded-document {
      @include link;
      color: styled.$main;
    }

    &--upload-link {
      @include link;
      color: styled.$red;
    }

    &--file-upload-cell {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    @mixin supporting-docs-table-cell-inner-wrapper {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      height: 32px;
    }

    &--file-upload-cell-inner-wrapper,
    &--override-cell-inner-wrapper {
      @include supporting-docs-table-cell-inner-wrapper;
    }

    &--file-upload-cell-action-button {
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }

    &--reviewed-cell {
      @include supporting-docs-table-cell-inner-wrapper;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: styled.$green;
        border-color: styled.$green;
      }
    }
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-header {
    border-bottom: 2px solid styled.$gray-divider;
  }

  textarea {
    resize: none;
    min-height: 70px;
  }

  &__panel-header-error {
    color: styled.$red;
    margin-left: 8px;
  }

  &__grid-error {
    color: styled.$red;
    margin-right: 4px;
  }

  &__checkbox {
    @include styled.row;
    flex: 1;
    flex-direction: row-reverse;
    font-weight: 500;
    color: styled.$gray-text;
  }

  &__label-extended {
    @include styled.row-spaced;
    flex: 1;
  }

  &__input-label-extended {
    .ant-form-item-label,
    .ant-form-item-label > label {
      @include styled.row-spaced;
      flex: 1;
      padding-bottom: 0;
    }

    .ant-checkbox {
      margin-bottom: 4px;
    }
  }

  &__form-item-no-margin.ant-form-item {
    margin-bottom: 0;
  }

  &__input-label-warning {
    label::after {
      display: inline-block !important;
      margin-left: 2px;
      margin-right: 4px;
      color: #faad14;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      font-weight: bold;
      content: '*';
    }
  }

  &__input-label-error {
    label::after {
      display: inline-block !important;
      margin-left: 2px;
      margin-right: 4px;
      color: #ed1a3a;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      font-weight: bold;
      content: '*';
    }
  }

  &__label-nomark {
    label::after {
      content: none !important;
    }
  }

  &__label-required::after {
    display: inline-block;
    margin-left: 2px;
    margin-right: 4px;
    color: #ed1a3a;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: bold;
    content: '*';
  }

  &__label {
    color: #9e9e9e;
    font-weight: bold;
    padding: 1px 0px 7px 0px;

    .ant-form label {
      display: flex;
    }
  }

  &__tooltip {
    vertical-align: middle;
    color: styled.$red;
    margin-left: 4px;
  }

  &__clickable-row {
    cursor: pointer;
  }

  &__subtab {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 300px;

    &--container {
      box-shadow: 0px 3px 15px #00000024;
      margin: 24px;
      padding: 16px 0;
    }

    &--item-container {
      padding: 0 16px;
      cursor: pointer;

      &:hover {
        background-color: styled.$light-gray-background;
      }
    }
  }

  @mixin icon {
    font-size: 18px;
    color: styled.$red;
  }

  &__input-action-icon {
    @include icon;
    cursor: pointer;
  }

  @mixin link {
    color: styled.$red;
    cursor: pointer;
    font-size: 14px;
    margin-top: -16px;
    margin-bottom: 24px;
    position: relative;
  }

  &__link-left {
    @include link;
    text-align: left;
  }

  &__link-right {
    @include link;
    text-align: right;
  }

  &__header {
    font-size: 16px;
    font-weight: bold;
    padding: 16px 0;
  }

  &__sub-header {
    font-size: 14px;
    font-weight: 600;
    padding: 16px 0;
    color: styled.$gray-text;
  }

  .ant-input-number-lg {
    width: 100%;
  }

  &__add-icon {
    float: right;

    .Button.ant-btn {
      height: 30px;
      line-height: 30px !important;
      border-radius: 6px;
    }

    .Button__small.ant-btn {
      padding: 0 8px;
    }

    .Button.ant-btn [data-icon-name] {
      font-size: 12px;
      vertical-align: middle;
      font-weight: bolder;
    }
  }

  &__buttons-container {
    padding-top: 60px;
  }

  &__modal {
    @include styled.tabs;
    @include styled.select;
    @include styled.form;
  }

  &__modal-sub-title {
    font-size: small;
  }

  &__errors-container {
    background-color: #f9edef;
    padding: 14px;

    &--title {
      font-weight: bold;
      padding-bottom: 8px;
    }
  }

  &__error-icon {
    font-size: 16px;
  }

  &__error-link {
    color: styled.$red;
    text-decoration: underline;
    cursor: pointer;
  }

  &__tab-error-icon {
    @include icon;
    font-size: 14px;
  }

  &__questionnaire-question {
    margin-bottom: 0;

    &--horizontal {
      .ant-form-item {
        flex-direction: row;
        display: flex;
        flex: 1;
        margin-bottom: 0;
      }
    }
  }

  &__questionnaire-question-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &__questionnaire-question-additional-info {
    margin-top: 15px;
    margin-bottom: 0;
  }

  &__assets-debts,
  &__incomes-expense {
    &--header {
      display: flex;
      flex: 1;
      border: 1px solid styled.$gray-divider;
      padding: 10px 12px;
      margin-bottom: 40px;

      .ant-space-item {
        @include styled.row;
        flex: 1;
      }

      .ant-space-item-split {
        flex: 1;
      }
    }

    &--table-title {
      padding-left: 16px;
      font-weight: bold;
    }

    // &--bordered-header-item {
    //   border-left: 1px solid styled.$gray-divider;
    //   padding: 6px;
    // }

    &--total-assets {
      font-size: 28px;
      color: styled.$green;
      font-weight: bold;
    }

    &--total-debts-container {
      border-left: 1px solid styled.$gray-divider;
      padding-left: 6px;
    }

    &--total-debts {
      font-size: 28px;
      color: styled.$red;
    }

    .ant-table-white .ant-table-tbody > tr > td {
      border-bottom: 2px dotted styled.$gray-divider;
    }
    .ant-table-white .ant-table-tbody > tr:last-child > td {
      border-bottom: 0;
    }
  }

  &__financial-difficulty-error-container {
    border: 1px solid styled.$red;
    padding: 24px 17px 0 17px;
    margin: 0;
  }

  &__income-expense-type {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: #0000008a;
    }
  }

  & .user-changed,
  &__modal .user-changed {
    & .CheckboxButtonStyle,
    & .CheckboxButtonStyle__checked {
      .ant-checkbox-wrapper {
        box-shadow: rgba(0, 144, 237, 0.4) 0px 0px 1px 2px;
      }
    }

    & .SearchLocationInput {
      input {
        box-shadow: inset rgba(0, 144, 237, 0.4) 0px 0px 1px 2px;
      }
    }
  }

  &__clickable-row.user-changed {
    //box-shadow: 0px 0px 1px 2px rgba(0, 144, 237, 0.4);

    & > td {
      border-top: 1px solid rgba(0, 144, 237, 0.4) !important;
      border-bottom: 1px solid rgba(0, 144, 237, 0.4) !important;

      &:first-child {
        border-left: 2px solid rgba(0, 144, 237, 0.4) !important;
      }

      &:last-child {
        border-right: 2px solid rgba(0, 144, 237, 0.4) !important;
      }
    }

    &:first-child > td {
      border-top: 2px solid rgba(0, 144, 237, 0.4) !important;
    }

    &:last-child > td {
      border-bottom: 2px solid rgba(0, 144, 237, 0.4) !important;
    }
  }
}
