@use '../../styles/index.scss' as styled;

.QuickActionsToolbar {
  font-size: 21px;
  color: styled.$red;
  border: 1px solid styled.$gray-border;
  padding: 6px 9px;
  margin-left: 10px;

  .anticon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-dropdown {
    box-shadow: 0px 0px 14px 0px styled.$box-shadow;
  }

  &__divider {
    width: 1px;
    display: flex;
    align-self: stretch;
    border-left: 1px solid styled.$gray-border;
    margin: 0 12px;
  }

  &__icon-flipped {
    width: 40px;
    @include styled.row-centered;
    transform: scale(-1, 1);
    cursor: pointer;
  }

  &__icon {
    width: 40px;
    @include styled.row-centered;
    cursor: pointer;
  }

  &__flag {
    color: styled.$red;
    font-weight: 600;
  }
}
