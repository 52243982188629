@use '../../../../../../styles/index.scss' as styled;

.OppositionLetterModal {
  @include styled.modal;
  @include styled.labels;
  @include styled.select;

  .ant-select-multiple .ant-select-selector {
    flex: 1;
  }

  &__footer {
    padding-top: 16px;
  }
}
