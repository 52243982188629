@use './basic' as styled;
@use './variables' as variables;

@mixin radio {
  .ant-radio-group {
    @include styled.row;
    justify-content: stretch;
  }

  .ant-radio-button-wrapper {
    @include styled.row-centered;
    flex: 1;
    line-height: 42px;
    height: 42px;
    font-weight: bold;
    font-size: 14px;

    &:hover {
      color: variables.$main;
      background: variables.$light-gray-background;
    }
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: variables.$red;
    border-color: variables.$red;
    color: variables.$white;

    &:focus-within {
      box-shadow: none;
    }

    &::before {
      background-color: variables.$red;
    }
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: transparent;
  }

  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    background: variables.$input-border;
    border-color: variables.$input-border;
    color: variables.$text-disabled;

    &::before {
      background-color: variables.$field-disabled;
    }
  }
}

@mixin radio-stretched {
  .ant-radio-button-wrapper {
    flex: 1;
  }
}

@mixin radio-extended {
  .ant-radio-button-wrapper {
    padding: 0 60px;
  }
}
