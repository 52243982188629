@use '../../styles/index.scss' as styled;

.Button_Custom {
  display: inline-block;

  .Button {
    &.ant-btn {
      // height: 42px;
      line-height: 42px;
      // color: #fff;
      border: 0;
      padding: 0 24px;
      // border-radius: 0;
      // background-color: styled.$light-gray-background;
      border-color: unset;
      font-weight: bold;
      min-width: 180px;
    }

    &.ant-btn[disabled]:not(.ant-btn-link),
    &.ant-btn[disabled]:not(.ant-btn-link):hover,
    &.ant-btn[disabled]:not(.ant-btn-link):focus {
      color: styled.$bright-text;
      background-color: styled.$disabled-gray-background;
    }

    &.ant-btn .anticon,
    &.ant-btn [data-icon-name] {
      font-size: 19px;
      vertical-align: -0.25em;
    }

    &.ant-btn .icon-from-custom-icon {
      display: inline-flex;
    }

    &.ant-btn [data-icon-name] + span,
    &.ant-btn .icon-from-custom-icon + span {
      margin-left: 8px;
    }

    &.ant-btn span + .icon-from-custom-icon {
      margin-left: 8px;
    }

    &.ant-btn.ant-btn-link {
      min-width: unset;
      font-weight: normal;
    }

    &.ant-btn[disabled].ant-btn-link {
      color: styled.$disabled-gray-background;
    }

    // &.ant-btn:hover {
    //   color: #000000;
    //   background: #f8f7f8;
    //   border: 1px solid #dadada;
    // }

    &.ant-btn:active,
    &.ant-btn:focus {
      color: styled.$bright-text;
    }

    button.ant-btn:focus &__small.ant-btn {
      color: styled.$dark-text;
    }

    &__no-padding.ant-btn {
      padding: 0 0;
    }
    &__small.ant-btn {
      padding: 0 15px;
    }
    &__medium.ant-btn {
      padding: 0 30px;
    }

    &__large.ant-btn {
      padding: 0 51px;
    }

    &__height-short.ant-btn {
      height: 32px;
      line-height: 32px;
    }

    &__height-tall.ant-btn {
      height: 52px;
      line-height: 52px;
    }

    &__height-short.ant-btn .anticon,
    &__height-tall.ant-btn .anticon {
      vertical-align: -0.25em;
    }

    &__narrow.ant-btn {
      min-width: unset;
    }

    &__primary {
      background-color: styled.$red;
    }
    &__primary.ant-btn:hover,
    &__primary.ant-btn:focus {
      color: styled.$bright-text;
      background: styled.$red-faded;
      border-color: styled.$red-faded;
    }

    &__secondary {
      background-color: styled.$orange;
    }
    &__secondary.ant-btn:hover,
    &__secondary.ant-btn:focus {
      color: styled.$bright-text;
      background: styled.$orange-faded;
      border-color: styled.$orange-faded;
    }

    &__cancel,
    &__darkgray {
      background-color: styled.$gray-background;
    }
    &__cancel.ant-btn:hover,
    &__cancel.ant-btn:focus,
    &__darkgray.ant-btn:hover,
    &__darkgray.ant-btn:focus {
      color: styled.$bright-text;
      background: styled.$gray-background-faded;
      border-color: styled.$gray-background-faded;
    }

    &__special {
      background-color: styled.$special-blue;
    }
    &__special.ant-btn:hover,
    &__special.ant-btn:focus {
      color: styled.$bright-text;
      background: styled.$special-blue-faded;
      border-color: styled.$special-blue-faded;
    }

    &__success {
      background-color: styled.$green;
    }
    &__success.ant-btn:hover,
    &__success.ant-btn:focus {
      color: styled.$bright-text;
      background: styled.$green-faded;
      border-color: styled.$green-faded;
    }

    &__link.ant-btn-link {
      color: styled.$red;
    }
    &__link.ant-btn-link:hover,
    &__link.ant-btn-link:focus {
      color: styled.$red-faded;
    }
  }
}
