.ViewClaimPage {
  .divider {
    margin: 0 0 10px 0;
  }
}

.datepicker_fullWidth {
  display: flex;

  [class~='ant-picker'] {
    height: 42px;
    flex: auto;
    max-width: 100%;
  }
}
