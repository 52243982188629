.person_row_container {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin-left: -10px;
  margin-right: -10px;
}

.person_row {
  display: flex;
  padding-bottom: 20px;
}
