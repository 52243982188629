@use '../../../../../../styles/index.scss' as styled;

.ReviewOppositionResponseModal {
  @include styled.modal;
  @include styled.labels;
  @include styled.select;
  @include styled.checkbox;

  &__footer {
    padding-top: 40px;
  }
}
