@use '../../styles/index.scss' as styled;

.DebtorSearchPage {
  @include styled.pagination;
  padding-top: 30px;

  &__divider-container {
    margin-top: 16px;

    .ant-divider-horizontal {
      margin: 0;
    }
  }

  &__dropdown-overlay {
    width: 360px;
    padding: 24px;
    box-shadow: 0px 0px 9px styled.$box-shadow;
    background-color: styled.$white;

    .ant-menu-vertical {
      border: 0;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: styled.$white;
    }
  }

  &__expand-icon {
    cursor: pointer;
  }

  &__download-icon {
    cursor: pointer;
    font-size: 22px;
    color: styled.$red;
  }

  &__print-icon {
    cursor: pointer;
    font-size: 20px;
    color: styled.$red;
  }

  &__results-empty-view {
    text-align: center;
    padding-top: 20px;
    font-size: 16px;
  }

  &__advanced-search {
    color: styled.$red;
    cursor: pointer;
    text-decoration: underline;
    text-align: end;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &__filters {
    @include styled.input;
    @include styled.select;
    @include styled.form;
    @include styled.labels;

    .ant-form-item-label > label {
      display: flex;
      flex: 1;
    }

    .ant-menu-title-content {
      display: flex;
      flex: 1;
    }

    .ant-select-multiple .ant-select-selector {
      flex: 1;
    }

    &--button {
      display: flex;
      flex: 1;

      .ant-btn {
        width: 100%;
      }
    }
  }

  &__action-icon {
    color: styled.$red;
    cursor: pointer;
    font-size: 16px;
  }

  &__table {
    .ant-table-thead > tr > th {
      background: styled.$subtab-active-background;
      color: styled.$light-gray-text;
    }

    .ant-table-tbody > tr > td {
      background-color: styled.$subtab-active-background;
      vertical-align: top;
    }

    .ant-table-tbody > tr:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr.ant-table-row-selected > td {
      border-top: 20px solid styled.$white;
    }

    .ant-table-tbody > .ant-table-expanded-row > td {
      background: styled.$light-gray-background;
    }
  }

  &__clickable-row {
    cursor: pointer;
  }

  &__cancelled-stage {
    color: styled.$red;
    font-weight: bold;
  }

  &__expandable-table {
    @include styled.table;

    .ant-table-thead > tr > th {
      background: styled.$light-gray-background;
      color: styled.$light-gray-text;
    }

    .ant-table-tbody > tr > td,
    .ant-table-tbody > tr:nth-child(2n) > td {
      background-color: styled.$light-gray-background;
    }

    .ant-table-tbody > tr > td,
    .ant-table-tbody > tr.ant-table-row-selected > td {
      border-top: 0 !important;
    }
  }

  &__clear {
    color: styled.$red;
    cursor: pointer;
    font-size: 12px;
    padding: 0;

    :visited {
      color: styled.$red;
    }
  }

  &__results-container {
    @include styled.column;
  }

  .ant-menu-vertical {
    border: 0;
  }

  &__print-table {
    display: none;

    @media print {
      display: block;
    }

    .PageTitle {
      margin-left: 25px;
      margin-top: 25px;
    }
  }

  &__print-word-break {
    .DebtorSearchPage__table div {
      word-break: break-all;
      white-space: normal;
    }
  }
}
