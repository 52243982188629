@use '../../../../styles/index.scss' as styled;

.UploadModal {
  @include styled.modal;
  @include styled.select;
  @include styled.input;
  @include styled.form;

  &__buttons-container {
    margin-top: 40px;
  }

  &__file-name {
    font-weight: bold;
    text-decoration: underline;
  }

  &__dragger {
    @include styled.column;
    align-items: center;
    padding-bottom: 15px;
    background-color: styled.$light-gray-background;
    border: 1px solid styled.$light-gray;
    cursor: pointer;

    &--title {
      font-weight: bold;
    }

    i {
      color: styled.$red;
      font-size: 40px;
    }
  }

  &__icon {
    font-size: 18px;
    color: styled.$red;
    cursor: pointer;
  }
}
