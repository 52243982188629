@use '../../styles/index.scss' as styled;

.AppTabs {
  [class~='ant-tabs-ink-bar'] {
    background: styled.$red;
  }

  [class~='ant-tabs-tab'] {
    margin: 0;
    padding: 10px 0;
    font-size: 14px;
  }
  [class~='ant-tabs-tab']:hover {
    color: styled.$main;
  }

  [class~='ant-tabs-nav'] [class~='ant-tabs-tab'] {
    margin: 0;
    padding: 10px 0;
    font-size: 14px;
  }

  [class~='ant-tabs-small'] > [class~='ant-tabs-nav'] [class~='ant-tabs-tab'] {
    padding: 8px 0;
  }
  [class~='ant-tabs-large'] > [class~='ant-tabs-nav'] [class~='ant-tabs-tab'] {
    padding: 16px 0;
    font-size: 16px;
  }

  [class~='ant-tabs-tab'] [class~='ant-tabs-tab-btn']:active,
  [class~='ant-tabs-tab'] [class~='ant-tabs-tab-btn']:focus {
    color: styled.$main;
  }
  // [class~='ant-tabs-tab'] [class~='ant-tabs-tab-active'][class~='ant-tabs-tab-btn'] {
  //   color: styled.$red;
  // }
  [class~='ant-tabs-tab'][class~='ant-tabs-tab-active'] [class~='ant-tabs-tab-btn'] {
    color: styled.$red;
  }

  [class~='ant-tabs'] > [class~='ant-tabs-nav'] {
    align-content: flex-end;
  }

  [class~='ant-tabs-nav'] [class~='ant-tabs-tab-btn'] {
    font-weight: bold;
    padding: 0 20px;
  }

  [class~='ant-tabs-card'] > [class~='ant-tabs-nav'] [class~='ant-tabs-tab'] {
    padding: 8px 16px;

    [class~='ant-tabs-tab-btn'] {
      padding: 0 8px;
    }
  }

  [class~='ant-tabs'][class~='ant-tabs-card']
    [class~='ant-tabs-tab']:not([class~='ant-tabs-tab-active'])
    [class~='ant-tabs-tab-btn'] {
    font-weight: normal;
  }

  [class~='ant-space-item'] [class~='ant-btn'] {
    border: none;
    color: styled.$main;
    height: 50px;
    padding: 18px 12px;
    display: flex;
    line-height: 18px;
    font-weight: bold;
  }

  [class~='ant-tabs-extra-content'] {
    padding-bottom: 2px;

    [class~='Button_Custom'] {
      [class~='Button'][class~='ant-btn'] {
        min-width: unset;
      }

      [class~='Button__primary'][class~='ant-btn']:hover,
      [class~='Button__primary'][class~='ant-btn']:focus {
        background-color: styled.$light-gray-background;
      }

      [class~='Button'][class~='ant-btn'] [data-icon-name] {
        font-size: 11px;
        font-weight: bold;
      }

      [class~='Button'][class~='ant-btn'] [data-icon-name][class~='extracontent-icon'] {
        font-size: 18px;
        margin-left: 30px;
        color: styled.$red;
        font-weight: normal;
      }
    }
  }
}
