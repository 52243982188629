.DebtorProfileInfoBlock {
  display: flex;
  box-shadow: 0px 3px 15px #00000024;
  padding: 30px 24px;
  margin-top: 24px;

  &__item {
    display: flex;
    margin-right: 45px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item-label {
    font-weight: bold;
  }

  &__item-value {
    margin-left: 8px;
  }

  &__left-side,
  &__right-side {
    flex: 1;
    display: flex;
  }

  &__right-side {
    align-items: center;
  }

  &__left-side {
    flex-wrap: wrap;
  }
}
