@use '../../../../../../../styles/index.scss' as styled;

.PADScheduleHistoryModal {
  @include styled.modal;

  &__data-row {
    padding: 6px 0;
  }

  &__footer {
    padding-top: 24px;
  }
}
