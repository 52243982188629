@use '../../../../styles/variables' as vars;

.NewAndViewHeader {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.breadcrumb_and_overview_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.subtitle_and_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subtitle_and_toolbar {
  display: flex;
  align-items: center;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
}

.status {
  display: inline-block;
  margin-left: 6px;
  text-transform: capitalize;
  color: vars.$green;
}

.toolbar {
  margin-left: 30px;
}
