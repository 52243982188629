@use '../../../styles/index.scss' as styled;

.FileAnalysisConfiguration {
  @include styled.tabs;

  &__tabs {
    padding-top: 24px;
  }

  &__edit-btn-container {
    margin-bottom: 6px;
  }

  .ant-tabs-content-holder {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
