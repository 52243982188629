@use '../../../../../styles/index.scss' as styled;

.WeeklySchedule {
  @include styled.input;
  @include styled.labels;
  @include styled.form;

  .ant-picker-range {
    width: 100%;
  }

  &__list {
    padding-bottom: 24px;
  }

  &__button {
    float: right;
  }

  &__divider {
    margin-top: 8px;
    border-top-width: 1.5px;
  }

  &__scheduling-note {
    padding-top: 40px;
  }
}
