@use '../../../../../../styles/index.scss' as styled;

.UpdateInfoModal {
  @include styled.modal;
  @include styled.form;
  @include styled.select;

  &__footer {
    padding-top: 60px;
  }
}
