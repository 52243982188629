@use '../../styles/variables' as vars;

.PDS_Icon {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
}

.bordered {
  // display: inline-flex;
  // justify-content: center;
  // align-items: baseline;
  border-radius: 3px;
  border: 1px solid vars.$red;
}
