.input_and_save_container {
  display: flex;
  align-items: center;
}

.status_input {
  flex: 60% 0 0;
}

.save_button_container {
  position: relative;
  padding-left: 10px;
  top: 3px;
}
