@use '../../../../../../../styles/index.scss' as styled;

.ChequePADRequestReviewModal {
  @include styled.modal;
  @include styled.form;
  @include styled.select;
  @include styled.input;
  @include styled.date-picker;

  &__data-row {
    padding: 6px 0;
  }

  &__footer {
    padding-top: 40px;
  }
}
