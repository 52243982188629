@use '../../styles/index.scss' as styled;

.UploadDataItem {
  &__history-icon {
    font-size: 18px;
    color: styled.$red;
    cursor: pointer;
    text-align: end;
  }

  &__review-icon {
    font-size: 22px;
    color: styled.$red;
    cursor: pointer;
  }
}
