@use '../../../../../../styles/index.scss' as styled;

.CourtRevivalModal {
  @include styled.modal;
  @include styled.labels;
  @include styled.checkbox;

  .ant-checkbox + span {
    padding-left: 0;
  }

  .ant-checkbox-wrapper {
    flex-direction: row-reverse;
  }

  &__checkbox {
    color: styled.$gray-text;
    font-weight: 600;

    &::after {
      display: inline-block;
      margin-left: 2px;
      margin-right: 4px;
      color: styled.$red;
      font-size: 16px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      font-weight: bold;
      content: '*';
    }
  }

  &__footer {
    padding-top: 16px;
  }
}
