@use '../../../../../../../styles/index.scss' as styled;

.RowActionMenu {
  &__action-menu {
    .ant-dropdown-menu-title-content {
      flex: auto;
      display: flex;
      align-items: center;
      gap: 10px;

      i {
        color: styled.$red;
        font-size: larger;
      }
    }

    .ant-dropdown-menu-item {
      min-width: 160px;
    }
  }
}
