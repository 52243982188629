.AlertModal {
  [radiogroupname]:has(.radio_group_in_label) {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 12px;

    & > [class~='ant-form-item-label'] {
      display: none;
    }

    & > [class~='ant-form-item-control'],
    & > [class~='ant-form-item-control'] [class~='ant-form-item-control-input'] {
      line-height: 25px;
      height: 25px;
    }
  }
}
