@use '../../../styles/index.scss' as styled;

.DashboardTable {
  @include styled.select;
  @include styled.form;

  &__table {
    @include styled.table;
  }

  &__table-empty {
    @include styled.table-empty;
  }

  &__priority-status {
    font-weight: bold;
    color: styled.$red;
  }

  &__exclamation-icon {
    font-size: 20px;
    color: styled.$red;
  }
  &__task-hyperlink {
    font-weight: bold;
    color: styled.$red;
    cursor: pointer;
    text-decoration: underline;
  }

  &__disabled-checkbox {
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: styled.$gray-divider;
    }
  }
}
