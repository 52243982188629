@use '../../styles/index.scss' as styled;

.TabsLeft__subtab {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 300px;
}

.TabsLeft__subtab_container {
  padding: 24px 14px;
  box-shadow: 0px 3px 15px #00000024;
  margin: 24px;
  position: sticky;
  top: 10px;

  [class~='tab-hidden'] {
    display: none;
  }
}
