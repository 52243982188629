@use '../../../../../../styles/index.scss' as styled;

.ResponseCourtAppearanceModal {
  @include styled.modal;
  @include styled.labels;
  @include styled.select;

  &__footer {
    padding-top: 100px;
  }
}
