@use '../../../../../../../styles/index.scss' as styled;

.PADRequestReviewModal {
  @include styled.modal;
  @include styled.form;
  @include styled.tabs;
  @include styled.select;
  @include styled.input;

  .ant-modal-body {
    padding-top: 0;
  }

  &__footer {
    padding-top: 24px;
  }
}
