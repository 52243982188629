@use '../../../../../styles/index.scss' as styled;

.Overrides {
  &__container {
    border-bottom: 1px dotted styled.$gray-divider;
    padding: 12px 0;
  }

  &__date-container {
    font-weight: bold;
  }

  &__entries-list {
    color: styled.$light-gray-text;
    font-weight: bold;
  }

  &__entry-item {
    padding-bottom: 16px;
  }

  &__delete-icon {
    color: styled.$red;
    font-size: 16px;
    cursor: pointer;
  }

  &__divider {
    margin-top: 8px;
    border-top-width: 1.5px;
  }
}
