@use '../../../../styles/index.scss' as styled;

.SelectWithResultingList {
  &__delete-icon {
    color: styled.$red;
    cursor: pointer;
    font-size: 16px;
  }

  &__add-icon {
    color: styled.$red;
    cursor: pointer;
    font-size: 9px;
    border: 1px solid styled.$red;
    padding: 0px 3px;
    border-radius: 3px;
    font-weight: 700;
  }

  &__select-field.ant-form-item {
    margin-bottom: 0;
  }

  &__list-item {
    .ant-form-item {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 24px;
    }
  }
}
