.DatePicker_Custom {
  display: flex;

  .ant-picker {
    padding: 4px 11px 4px;

    &:hover,
    &-focused {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
      //border-right-width: 1px !important;
    }

    &.ant-picker-large {
      padding: 6.5px 11px;
    }
  }

  &.full-width {
    .ant-picker {
      flex: auto;
    }
  }
}
