@use '../../../../styles/index.scss' as styled;
.AvailableTimeslots {
  @mixin input-row-container {
    @include styled.row;
    gap: 20px;
  }

  &__inputs-container {
    @include input-row-container;
  }

  &__buttons-container {
    @include input-row-container;
    padding-top: 24px;
    align-items: flex-end;
  }

  &__appointments-container {
    border: 1px solid styled.$light-gray;
    background-color: styled.$light-gray-background;
    height: 266px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__empty-view {
    @include styled.row-centered;
    height: 266px;
    flex: 1;
  }

  &__appointment-item {
    background: styled.$light-gray-background;
    border-bottom: 1px solid styled.$light-gray;
    padding: 4px;
  }

  @mixin option {
    @include styled.row-centered;
    justify-content: flex-start;
    flex: 1;
    padding: 4px 8px;
  }

  &__option-time {
    @include option;
    font-weight: bold;
  }

  &__option-button {
    @include option;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: styled.$gray-border;
      color: styled.$main;
    }

    &-active,
    &-active:hover {
      background-color: styled.$red;
      color: styled.$white;
    }

    &-disabled,
    &-disabled:hover {
      background-color: styled.$light-gray-background;
      cursor: not-allowed;
    }

    &-active.AvailableTimeslots__option-button-disabled {
      background-color: styled.$gray-border;
      color: styled.$main;
    }

    i {
      margin-right: 6px;
    }
  }
}
