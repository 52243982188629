@use '../../../../../../styles/variables' as vars;

.SecondaryRecordsDisplay {
  display: flex;
  flex-direction: column;

  [class~='ant-collapse-borderless'] > [class~='ant-collapse-item'] {
    border-bottom: none;

    & > [class~='ant-collapse-header'] {
      border-bottom: 1px solid vars.$gray-divider;
    }

    & > [class~='ant-collapse-content'] {
      background-color: vars.$white;
    }
  }
}

.header_row {
  display: flex;
  align-items: center;
  position: absolute;
  top: 3px;
  right: 45px;
  bottom: 0;
  left: 56px;
  font-size: 14px;
  font-weight: normal;
}

.header_titles {
  display: flex;
  align-items: center;
  padding: 12px 45px 12px 56px;
  font-size: 14px;
  font-weight: 600;
  color: vars.$gray-text;
}

.header_values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
}
