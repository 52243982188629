@use '../../../../styles/variables' as vars;

.BackToOverviewButton {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.button_text {
  font-size: 14px;
  color: vars.$red;
}

.icon {
  margin-left: 8px;
}

.icon i {
  font-weight: bold;
}
