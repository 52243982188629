@use '../../../styles/variables' as vars;

.AppRadioGroup {
  [class~='ant-radio-group'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
  }

  [class~='ant-radio-button-wrapper-checked']:not([class*=' ant-radio-button-wrapper-disabled'])[class~='ant-radio-button-wrapper']:first-child {
    border-right-color: transparent;
  }

  [class~='ant-radio-button-wrapper'],
  [class~='ant-radio-button-wrapper-checked']:not([class*=' ant-radio-button-wrapper-disabled']):focus-within {
    box-shadow: unset;
  }

  [class~='ant-radio-group-solid'] [class~='ant-radio-button-wrapper-checked'],
  [class~='ant-radio-group-solid']
    [class~='ant-radio-button-wrapper-checked']:not([class~='ant-radio-button-wrapper-disabled']:hover) {
    background: vars.$red;
    border-color: vars.$red;
    color: vars.$white;
  }

  [class~='ant-radio-group-solid'] [class~='ant-radio-button-wrapper-checked']::before,
  [class~='ant-radio-group-solid']
    [class~='ant-radio-button-wrapper-checked']:not([class*=' ant-radio-button-wrapper-disabled']):hover::before {
    background-color: vars.$red;
  }

  [class~='ant-radio-button-wrapper'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    line-height: 42px;
    height: 42px;
    font-weight: bold;
    font-size: 14px;
  }

  [class~='ant-radio-button-wrapper']:hover {
    color: vars.$main;
    background: vars.$light-gray-background;
  }
}
