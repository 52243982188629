@use '../../styles/variables' as cssVars;

.ErrorsContainer {
  background-color: #f9edef;
  padding: 14px;
  margin-bottom: 20px;

  [class~='title'] {
    font-weight: bold;
    padding-bottom: 8px;
  }

  [class~='error-icon'] {
    color: cssVars.$red;
  }
  [class~='warning-icon'] {
    color: cssVars.$orange;
  }
}
