.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding-top: 60px;
}

.ButtonContainer.inline {
  display: inline-flex;
  padding-top: 0;
}
