@use '../../../../../styles/index.scss' as styled;

.Proposal {
  @include styled.select;
  @include styled.form;
  @include styled.labels;
  @include styled.collapse;
  @include styled.table;

  &__section-header {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 16px;
  }

  &__info-row {
    background-color: styled.$light-gray-background;
    padding: 10px;
  }

  &__form-container {
    padding-top: 12px;
  }

  &__section-header:not(:first-child) {
    padding-top: 24px;
  }

  &__form-item {
    padding: 12px 0;
    border-bottom: 2px dotted styled.$gray-divider;
  }

  &__upload {
    color: styled.$red;
    font-weight: 600;
    cursor: pointer;
  }

  &__add-appointment {
    color: styled.$red;
    font-weight: bold;
    cursor: pointer;
  }

  &__add-icon {
    font-size: 9px;
    font-weight: 700;
    padding: 0 2px;
    border: 1px solid styled.$red;
    border-radius: 2px;
  }
}
