@use '../../../../styles/index.scss' as styled;

.TaskActionEditor {
  @include styled.radio;
  @include styled.labels;
  @include styled.tabs;
  @include styled.tabs-title-lg;
  @include styled.select;
  @include styled.modal;
  @include styled.form;
  @include styled.date-picker;

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-input-lg {
    font-size: 14px;
  }

  &__info-container {
    margin-bottom: 12px;
  }

  &__tabs-container {
    margin-top: 24px;
  }

  &__search-container {
    .ant-select-selector {
      flex: 1;
    }

    .ant-btn {
      border-left-width: 0;
      outline: 0;
    }
  }

  &__footer {
    padding-top: 40px;
  }
}
