@use '../../../styles/index.scss' as styled;

.Allocations {
  @include styled.input;
  @include styled.table;
  @include styled.pagination;
  @include styled.select;
  @include styled.checkbox;
  @include styled.form;

  .ant-input-search-large .ant-input-search-button {
    height: 42px;
  }

  .ant-form-item-label > label {
    margin-right: 8px;
  }
}
