@use './variables' as variables;
@use './pagination.scss' as styled;

@mixin table {
  @include styled.pagination;

  .ant-table-thead > tr > th {
    padding: 12px;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: variables.$red;
  }

  .ant-table-thead > tr > th {
    background: variables.$white;
    color: variables.$gray-text;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: variables.$gray-divider;
  }

  .ant-table-tbody > tr.ant-table-expanded-row > td {
    background-color: variables.$white;
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
    border-bottom: 32px solid variables.$white;
  }

  .table-row-light {
    background-color: variables.$white;
  }

  .table-row-dark {
    background-color: variables.$light-gray-background;
  }
}

@mixin table-empty {
  @include table;

  .ant-table-tbody > tr:nth-child(2n -1) > td {
    background-color: variables.$white;
  }
}
