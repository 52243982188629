@use '../../../../../styles/index.scss' as styled;

.WeeklyRow {
  @include styled.select;

  padding: 12px 0;
  border-bottom: 1px dotted styled.$gray-divider;

  &__unavailable-text {
    color: styled.$light-gray-text;
    font-weight: bold;
  }

  &__delete-icon {
    color: styled.$red;
    font-size: 16px;
    cursor: pointer;
  }

  &__error {
    color: styled.$red;
  }
}
