@use '../../../../../styles/index.scss' as styled;

.Discharge {
  @include styled.select;
  @include styled.form;
  @include styled.labels;
  @include styled.input;
  @include styled.radio;

  &__add-icon {
    border: 1px solid styled.$red;
    font-size: 10px;
    padding: 0 3px;
    font-weight: 700;
    border-radius: 2px;
  }
}
