@use '../../styles/index.scss' as styled;

.AppointmentTable {
  @include styled.table;

  &__empty-table {
    @include styled.table-empty;
  }

  &__subtitle {
    color: styled.$gray-text;
    font-weight: bold;
    margin-right: 8px;
  }

  &__subtitle-status-value {
    color: styled.$gray-text;
    font-weight: bold;
    margin-right: 8px;
    color: styled.$gray-text;
    font-weight: bold;
  }

  &__clickable-icon {
    cursor: pointer;
  }

  &__action-icon {
    cursor: pointer;
    color: styled.$red;
  }

  &__action-name {
    margin-left: 8px;
  }

  &__add-action {
    @include styled.row;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 600;
    color: styled.$red;
    opacity: 0;
    cursor: pointer;

    span {
      white-space: nowrap;
      margin-left: 6px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__edit-icon {
    color: styled.$red;
    font-size: 18px;
    cursor: pointer;
  }
}
