@use '../../styles/index' as styled;

.SendEmailConfirmation {
  @include styled.tabs;
  @include styled.tabs-title-lg;
  @include styled.select;
  @include styled.modal;
  @include styled.form;

  &__footer {
    padding-top: 24px;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ql-container {
    height: 200px;
  }

  textarea {
    resize: none;
  }

  .ant-form-item-has-error {
    .ql-toolbar.ql-snow {
      border-right-color: styled.$red;
      border-top-color: styled.$red;
      border-left-color: styled.$red;
    }

    .ql-container.ql-snow {
      border-right-color: styled.$red;
      border-bottom-color: styled.$red;
      border-left-color: styled.$red;
    }
  }
}
