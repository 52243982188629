@use '../../../../styles/index' as styled;

.CalendarAvailability {
  &__cell-container {
    @include styled.column;
    flex: 1;
    align-content: center;
    justify-content: center;
    padding: 12px;
    text-align: center;
    overflow: auto;
  }

  &__cell-item {
    @include styled.row;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 6px;
    margin-bottom: 6px;
  }
}
