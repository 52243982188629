@use '../../styles/index.scss' as styled;

.AddressComponent {
  @include styled.form;
  @include styled.labels;
  @include styled.collapse;
  @include styled.select;
  @include styled.input;
  @include styled.date-picker;
  @include styled.checkbox;

  &__delete-icon {
    font-size: 18px;
    cursor: pointer;
    color: styled.$red;
    padding-bottom: 7px;
  }
}
