@use '../../styles/index.scss' as styled;

.SwitchWithIcon {
  &.ant-switch {
    color: styled.$gray-text;
    background-color: styled.$gray-text;
  }

  &.ant-switch-checked {
    color: styled.$red;
    background-color: styled.$red;
  }

  .ant-switch-handle::before {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-family: 'FabricMDL2Icons';
    content: '\EE35';
  }
}
