@use '../../../../styles/index.scss' as styled;

.TaskActionTable {
  @include styled.table;
  @include styled.pagination;

  &__subtitle {
    color: styled.$gray-text;
    font-weight: bold;
    margin-right: 8px;
  }

  &__subtitle-status-value {
    color: styled.$gray-text;
    font-weight: bold;
    margin-right: 8px;
    color: styled.$inactive-tab;
    font-weight: bold;
  }

  &__clickable-icon {
    cursor: pointer;
  }

  &__action-icon {
    cursor: pointer;
    color: styled.$red;
  }

  &__action-name {
    margin-left: 8px;
  }

  &__edit-btn {
    @include styled.row;
    justify-content: flex-end;

    &--icon {
      color: styled.$red;
      font-size: 18px;
      text-align: right;
      cursor: pointer;
    }
  }

  &__add-action {
    @include styled.row;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: 600;
    color: styled.$red;

    cursor: pointer;

    &--title {
      white-space: nowrap;
      margin-right: 6px;
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__plus-icon {
    border-width: 1px;
    border-style: solid;
    padding: 0px 3px;
    border-radius: 3px;
    font-weight: 700;
    font-size: 9px;
  }
}
