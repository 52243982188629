@use '../../../styles/index.scss' as styled;

.MyProfile {
  @include styled.input;
  @include styled.labels;

  &__subtitle {
    font-weight: bold;
    padding-top: 18px;
    padding-bottom: 32px;
  }
}
