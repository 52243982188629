@use '../../../../../styles/index.scss' as styled;

.CopyDropdown {
  @include styled.column;
  align-items: flex-start;
  padding: 12px 8px;
  margin-left: 1px;
  margin-top: -1px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
  }

  .ant-checkbox-group-item {
    margin-bottom: 6px;
  }

  &__copy-icon {
    color: styled.$gray-background;
    font-size: 16px;
    cursor: pointer;
  }
}
