@use './basic.scss' as styled;
@use './variables' as variables;

@mixin form {
  .ant-form-item {
    flex: 1;
  }

  @include labels;

  .ant-form-item.readonly-field {
    .ant-form-item-label > label {
      color: variables.$gray-text;
    }
    .ant-input[readonly],
    .ant-input[readonly]:hover,
    .ant-input[readonly]:focus,
    .ant-select .ant-select-selctor,
    .ant-select:hover .ant-select-selctor,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-select:focus .ant-select-selctor,
    .ant-select:not(.ant-select-disabled):focus .ant-select-selector {
      border-color: variables.$input-border;
      box-shadow: unset;
      color: variables.$light-gray-text;
      cursor: not-allowed;
    }
  }

  .user-changed {
    .ant-input,
    .ant-select-selector,
    .ant-input-number,
    .ant-picker {
      box-shadow: inset rgba(0, 144, 237, 0.4) 0px 0px 1px 2px;
    }

    .ant-radio-group,
    .ant-checkbox {
      box-shadow: rgba(0, 144, 237, 0.4) 0px 0px 1px 2px;
    }
  }
}

@mixin labels {
  .ant-form-item-label {
    & > label {
      color: #9e9e9e;
      font-weight: 600;
    }
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: variables.$red;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    font-weight: 600;
    content: '*';
  }
}
