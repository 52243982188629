@use '../../../styles/index.scss' as styled;

.Availability {
  &__tab {
    display: flex;
    gap: 8px;
  }

  .ant-tabs-tab-active {
    background-color: styled.$red;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: styled.$white !important;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}
