@use '../../../../../../styles/index.scss' as styled;

.ChequeRequisitionHistoryModal {
  @include styled.table;
  @include styled.modal;

  .ant-modal-body {
    padding-top: 0;
  }

  &__footer-container {
    padding-top: 24px;
  }
}
