@use '../../styles/index' as styled;

.BlockWithLabel {
  @include styled.column;
  padding: 14px 0;

  @mixin label {
    font-weight: bold;
    color: styled.$gray-text;
  }

  &__horizontal {
    @include styled.row;
    padding-bottom: 12px;

    &__label {
      @include label;
      padding-right: 14px;
    }
  }

  &__label {
    @include label;
    padding-bottom: 14px;
  }
}
