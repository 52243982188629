@use '../../../styles/index.scss' as styled;

.OfficeLocations {
  @include styled.input;
  @include styled.table;
  @include styled.pagination;

  .ant-input-search-large .ant-input-search-button {
    height: 42px;
  }

  &__modal {
    @include styled.modal;
    @include styled.labels;
    @include styled.form;
    @include styled.checkbox;
    @include styled.date-picker;

    &--footer {
      padding-top: 24px;
    }
  }
}
