@use './variables' as variables;

@mixin select {
  .ant-select {
    display: flex;
    flex: 1 1;
  }

  .ant-select-selector {
    width: 100%;
  }

  .ant-select-arrow {
    svg {
      fill: variables.$main;
    }
  }

  .ant-select-disabled .ant-select-arrow {
    svg {
      fill: variables.$text-disabled;
    }
  }

  .ant-select-lg {
    font-size: 14px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 42px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 42px;
  }
}
