@use '../../../../styles/index' as styled;

.SMSEditor {
  @include styled.tabs;
  @include styled.tabs-title-lg;
  @include styled.select;
  @include styled.modal;
  @include styled.form;

  &__row {
    @include styled.row;
    justify-content: flex-end;
    gap: 12px;
  }

  .InputBlock {
    padding-bottom: 24px;
  }

  &__inputs-row-container {
    @include styled.row;
    gap: 8px;

    .InputBlock {
      flex: 1;
    }
  }

  .ql-container {
    height: 200px;
  }

  textarea {
    resize: none;
  }
}
