@use '../../../../../styles/index.scss' as styled;

.SignUp {
  @include styled.form;
  @include styled.select;
  @include styled.date-picker;

  &__radio {
    @include styled.radio;
  }

  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked::before {
    background-color: styled.$disabled-gray-background;
    border-color: styled.$disabled-gray-background;
  }
}
