@use '../../../../styles//index.scss' as styled;

.DebtorVisibility {
  @include styled.modal;

  &__labels {
    font-size: 14px;
    color: styled.$gray-text;
    font-weight: bold;
    padding: 12px 0;
  }

  &__list {
    max-height: 300px;
    min-height: 200px;
    overflow-y: auto;
  }

  &__list-item {
    @include styled.row;
    padding: 4px 0;
    background-color: styled.$white;
    cursor: move;
    padding: 10px 0;
    border-bottom: 1px dotted styled.$gray-border;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__buttons-container {
    margin-top: 40px;
  }

  &__warning {
    padding-bottom: 24px;
    color: styled.$red;
  }
}
