.FormSectionTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 16px 0;

  &[class~='no-top-padding'] {
    padding-top: 0;
  }
}

.title_icon {
  margin-left: 8px;

  [data-icon-name] {
    vertical-align: -0.25em;
  }
}
