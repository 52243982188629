.DetailsTable {
  display: block;
}

.actions_column {
  text-align: right;
}

.record_icon_wrapper {
  padding: 0 10px;
  opacity: 0;
  transition: 0.3s;
}

.DetailsTable [class~='ant-table-row']:hover .record_icon_wrapper {
  opacity: 1;
}
