@use '../../../styles/index.scss' as styled;

.DocumentsContent {
  @mixin link {
    cursor: pointer;
  }

  &__tabs {
    margin-top: 24px;

    @include styled.tabs;

    .ant-tabs-ink-bar {
      background: transparent;
    }

    .ant-tabs-tab-btn {
      padding: 0 8px;
    }

    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      .ant-tabs-tab-btn {
        font-weight: normal;
      }
    }
  }

  &__overview-icon {
    display: flex;
    gap: 10px;
    cursor: pointer;
    color: styled.$red;
    margin-right: 15px;
  }

  &__link {
    &--secondary {
      @include link;
      color: styled.$gray-background;
    }

    &--primary {
      @include link;
      color: styled.$red;
    }
  }

  &__basic-icon {
    color: styled.$red;
    font-size: large;
    cursor: pointer;
    margin-right: 15px;
  }
}
