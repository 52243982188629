.Panels {
  &__table {
    .ant-checkbox-wrapper::before {
      margin-right: 12px;
      position: relative;
      top: 2px;
      visibility: hidden;
      content: url('../components/check.svg');
    }
  }

  &__selected-row {
    .ant-checkbox-wrapper::before {
      visibility: visible;
    }
  }

  &__trade-container {
    width: calc(100vw - 26px * 2 - 80px);
  }
}
