@use '../../styles/index.scss' as styled;

.Calendar {
  .ant-picker-content {
    table-layout: auto;
  }

  .ant-picker-calendar {
    padding: 16px;
    border: 1px solid styled.$light-gray;
    margin-bottom: 16px;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    text-align: center;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    background-color: styled.$light-blue;
    margin: 4px;
    padding: 0;
    border-top: 1px solid styled.$light-gray;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
  }

  .ant-picker-cell-disabled > .ant-picker-calendar-date {
    background-color: styled.$white !important;
  }

  .ant-picker-cell-disabled {
    color: styled.$text-disabled;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:not(.ant-picker-cell-disabled)
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value,
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected:not(.ant-picker-cell-disabled):hover
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value {
    color: styled.$main;
    background-color: transparent;
  }

  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell-selected
    .ant-picker-calendar-date
    .ant-picker-calendar-date-value {
    color: styled.$text-disabled;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    margin-right: 6px;
  }
}
