@value value_wrapper, value from '../../../../../../components/DataItem/DataItem.module.scss';

.ExpandableRecordsDisplay {
  border-top: 2px solid #e8e8e8;
}

.ExpandableRecordsDisplay_Collapse {
  background-color: #ffffff;
  font-size: 14px;

  [class~='ant-collapse-item'] {
    border-bottom: none;
  }

  [class~='ant-collapse-header'] {
    border-bottom: 2px dotted #e8e8e8;
  }
}

.header_row {
  display: flex;
  align-items: center;
  position: absolute;
  top: 3px;
  right: 45px;
  bottom: 0;
  left: 40px;
  font-size: 14px;
  font-weight: normal;
}

.lead_value {
  width: 235px;
  padding-right: 10px;
}

.header_values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
}

.header_value > .value_wrapper {
  position: relative;
  flex: 1 0 0;

  & > .value {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 30px;
  }
}

.record_icon_wrapper {
  position: absolute;
  right: 0;
  padding: 0 10px;
  // display: none;
  opacity: 0;
  transition: 0.3s;
}

.ExpandableRecordsDisplay [class~='ant-collapse-item']:hover .record_icon_wrapper {
  // display: block;
  opacity: 1;
}
