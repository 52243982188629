@use '../../styles/index.scss' as styled;

.UploadSupportingDocumentsModal {
  @include styled.modal;
  @include styled.labels;
  @include styled.form;

  .ant-modal-body {
    padding-top: 12px;
  }

  .ant-form-item-label {
    padding-top: 0;
  }

  &__summary {
    font-size: 14px;
    color: styled.$gray2;
    font-weight: 600;
  }

  &__footer {
    padding-top: 16px;
  }
}
