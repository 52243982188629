@use '../../styles/index.scss' as styled;

.Breadcrumb {
  &__item {
    color: styled.$main;

    a {
      color: styled.$red;
    }
  }

  &__chevron {
    font-size: 12px;
    vertical-align: middle;
    color: styled.$inactive-tab;
  }
}
