@use '../../styles/index.scss' as styled;
.CheckboxButtonStyle {
  @mixin button {
    @include styled.row-centered;
    text-align: center;
    min-height: 42px;
    font-weight: bold;
    cursor: pointer;

    .ant-checkbox {
      display: none;
    }
  }

  label {
    @include button;
    background: styled.$light-gray-background;
    border: 1px solid styled.$light-gray;

    .ant-checkbox-wrapper {
      color: styled.$main;
    }
  }

  &__checked {
    label {
      @include button;
      border: 0;
      background: styled.$red;
    }

    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      background: styled.$gray-divider;
      border: 1px solid styled.$input-border;
    }

    .ant-checkbox-wrapper {
      color: styled.$white;
    }
  }
}
