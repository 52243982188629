@use '../../../styles/index.scss' as styled;

.ActionTable {
  @include styled.table;
  @include styled.pagination;
  @include styled.select;
  @include styled.form;

  &__action-icon {
    cursor: pointer;
    font-size: 18px;
    color: styled.$red;
  }
}
