@use '../../styles/variables' as vars;

.DataItem {
  display: flex;
}

.DataItem.vertical {
  flex-direction: column;
}

.label_wrapper {
  display: flex;
  align-items: center;
}

.label {
  font-weight: 600;
  color: vars.$gray-text;
  letter-spacing: -0.02em;
  font-size: 14px;
}

.label_icon {
  display: flex;
  align-items: baseline;
  margin-left: 15px;
  font-size: 20px;
  font-weight: bold;
}

.label_icon i[data-icon-name] {
  transform: translateY(0px);
}

.DataItem.horizontal .label_wrapper,
.DataItem.horizontal .value_wrapper {
  padding-right: 8px;
}

.value_wrapper {
  display: flex;
  align-items: center;
  color: vars.$dark-text;
}

.DataItem.inverted .label {
  color: vars.$dark-text;
}
.DataItem.inverted .value_wrapper {
  order: -1;
}

.DataItem.vertical .label.regular {
  font-size: 14px;
}
.DataItem.vertical .label.small {
  font-size: 12px;
}

.DataItem.vertical .value {
  letter-spacing: -0.05em;
}

.DataItem.vertical .value,
.DataItem.vertical .value.currency {
  font-weight: 500;
}
.DataItem.vertical .value.small {
  font-size: 14px;
}
.DataItem.vertical .value.regular {
  font-size: 30px;
}
.DataItem.vertical .value.large {
  font-size: 48px;
}

.DataItem.vertical.inverted .value {
  font-size: 25px;
  font-weight: 600;
}

.extra_text {
  font-weight: bold;
  color: vars.$dark-text;
}
.extra_text.extra_text_small {
  font-size: 14px;
  margin-left: 5px;
}
.DataItem.vertical .extra_text.extra_text_small {
  margin-top: 10px;
}
.extra_text.extra_text_large {
  font-size: 48px;
  letter-spacing: -0.05em;
  margin-left: 12px;
}

.info_bubble {
  margin-left: 10px;
}

.value_icon {
  margin-left: 8px;
  font-weight: bold;
  font-size: 14px;
}
.DataItem.vertical .value_icon {
  font-size: 18px;
  transform: translateY(3px);
}

.extra_content {
  margin-left: 10px;
}
.extra_content.at_right {
  margin-left: auto;
}

.DataItem .label.bold,
.DataItem .value.bold,
.DataItem.vertical .label.bold,
.DataItem.vertical .value.bold,
.DataItem.vertical.inverted .label.bold,
.DataItem.vertical.inverted .value.bold,
.DataItem.inverted .label.bold,
.DataItem.inverted .value.bold {
  font-weight: bold;
}
