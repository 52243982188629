@use '../../styles/index.scss' as styled;

.AttachmentsTab {
  @include styled.radio;
  @include styled.radio-extended;
  @include styled.select;
  @include styled.form;
  @include styled.labels;
  padding-bottom: 24px;

  &__top-container {
    @include styled.row;
    gap: 8px;
  }

  &__files {
    @include styled.column;
    gap: 12px;
    padding-bottom: 50px;
  }

  &__file-label {
    flex: 1;
  }

  &__file-name {
    font-weight: bold;
    text-decoration: underline;
  }

  &__dragger {
    @include styled.column;
    align-items: center;
    padding-bottom: 15px;
    background-color: styled.$light-gray-background;
    border: 1px solid styled.$light-gray;
    cursor: pointer;
    max-height: 180px;

    &--title {
      font-weight: bold;
    }

    i {
      color: styled.$red;
      font-size: 40px;
    }
  }

  &__upload-item {
    padding-right: 10px;
  }

  &__upload-list {
    padding-top: 24px;
  }

  &__icon {
    font-size: 18px;
    color: styled.$red;
    cursor: pointer;
  }

  &__supporting-docs-label {
    @include styled.column;

    &--list-item {
      color: styled.$main;
      font-weight: normal;
    }

    &--list-item:first-child {
      padding-top: 6px;
    }

    &--hint-icon {
      color: styled.$red;
      font-size: 16px;
      cursor: pointer;
    }
  }

  &__skip-message {
    padding: 12px;
    background-color: styled.$light-blue;
    font-weight: bold;
    margin-bottom: 24px;

    white-space: pre-wrap;
  }

  &__skip-cross {
    color: styled.$dark-blue;
    cursor: pointer;
  }

  &__newFiles {
    label {
      width: 100%;
    }
  }
}
