@use '../../styles/index.scss' as styled;

.CalendarHeader {
  @include styled.row-centered;

  &__month {
    margin: 8px;
    font-weight: bold;
  }

  &__large-ruler-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    border: 1px solid styled.$gray-divider;
    padding: 8px 12px;
  }
}
