@use '../../../../styles/index.scss' as styled;

.Note {
  .ant-comment-inner {
    padding: 8px 0;
  }
  .ant-comment-content {
    background-color: #f8f7f8;
    border-radius: 9px;
    padding: 12px;
  }

  .ant-comment-content-author {
    position: relative;
  }

  .ant-avatar {
    background-color: styled.$gray-background-avatar;
  }

  .ant-comment-content-author-time {
    color: styled.$light-gray-text;
    padding-bottom: 10px;
  }

  &__header {
    &--icons-container {
      @include styled.row;
      position: absolute;
      top: 0;
      right: 0;

      i {
        font-size: 16px;
        margin-right: 7px;
        cursor: pointer;
        color: styled.$red;
      }
    }

    &--icons-container-hover {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }
}
