@use '../../../../styles/index.scss' as styled;

.DocumentsTable {
  @include styled.table;
  @include styled.pagination;

  &__preview-tab {
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  &__table {
    &-empty {
      @include styled.table-empty;
    }
  }
  &__header {
    margin-top: 10px;
    margin-right: 15px;
  }

  &__filters,
  &__add {
    @include styled.row;
    gap: 6px;
    font-weight: bold;
    color: styled.$red;
    cursor: pointer;
  }

  &__clear {
    font-size: smaller;
    color: styled.$red;
    margin-top: 2px;
    cursor: pointer;
  }

  &__more-icon {
    font-size: large;
    cursor: pointer;
  }

  @mixin link {
    cursor: pointer;
  }

  &__link {
    &--secondary {
      @include link;
      color: styled.$gray-background;
    }

    &--primary {
      @include link;
      color: styled.$red;
    }
  }

  &__action-menu {
    .ant-dropdown-menu-title-content {
      flex: auto;
      display: flex;
      align-items: center;
      gap: 10px;

      i {
        color: styled.$red;
        font-size: larger;
      }
    }
  }

  &__filters-menu {
    @include styled.column;
    align-items: center;
    padding: 12px 8px;

    .ant-dropdown-menu-item {
      padding: 16px 12px;
    }

    .ant-dropdown-menu-item:hover {
      background-color: styled.$white;
    }
  }

  &__filters-button {
    padding: 0 8px;
  }

  &__basic-icon {
    color: styled.$red;
    font-size: large;
    cursor: pointer;
    margin-right: 15px;
  }

  &__row {
    cursor: pointer;

    &--selected {
      cursor: pointer;

      td {
        background-color: styled.$dark-cell-background !important;
      }
    }
  }

  &__divider {
    margin-top: 18px;
    margin-bottom: 18px;
  }

  &__status-link {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }

  .ant-switch-checked {
    background-color: styled.$red;
  }
}

.ant-table-filter-dropdown-btns {
  .ant-btn-primary {
    border-color: styled.$red;
    background: styled.$red;
  }
}
