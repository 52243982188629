@use '../../../../styles/variables' as vars;
.LineItemsDisplay {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0px 3px 15px #00000024;
}

.title_and_edit_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-bottom: 15px;
}

.title,
.group_title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;
}

.line_item_groups_container,
.line_item_group_container {
  display: flex;
  flex-direction: column;
}

.line_item_group_container {
  margin-top: 15px;
}

.group_title {
  padding-bottom: 8px;
}

.line_item {
  padding-bottom: 8px;
}

.subtotal {
  border-top: 1px solid vars.$gray-divider;
  padding-top: 8px;
}
