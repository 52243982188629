@use './variables' as variables;
@use './basic.scss' as basic;

@mixin collapse {
  .ant-collapse {
    background-color: variables.$white;
    border: 0;
  }

  .ant-collapse-header {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding: 18px 1px;
  }

  .ant-collapse-item:not(.ant-collapse-item-active) {
    &.user-changed {
      box-shadow: 0px -1px 1px 3px rgba(0, 144, 237, 0.4);
    }
  }
}
