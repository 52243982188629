@use '../../styles/variables' as vars;

.NewClaimPage {
  .label_text {
    color: vars.$gray-text;
    font-size: 14px;
    font-weight: bold;
  }

  .divider {
    margin: 0 0 10px 0;
  }

  .file_preview_header {
    display: flex;

    & > *:not(:first-child) {
      margin-left: 10px;
    }
  }
}
.label {
  font-weight: 600;
  color: vars.$gray-text;
  letter-spacing: -0.02em;
  font-size: 18px;
  padding-top: 30px;
}
.label_n_img_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: auto;
  height: 50%;
  width: 220px;
  align-items: center;
  text-align: center;
}
