@use '../../../styles/index.scss' as styled;

.ReceiptEditor {
  @include styled.radio;
  @include styled.form;
  @include styled.select;
  @include styled.input;
  @include styled.date-picker;

  &__title-container {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;

    i {
      margin-left: 6px;
      color: #ed1a3a;
      vertical-align: sub;
      cursor: pointer;
    }
  }

  &__form {
    margin-top: 40px;
  }

  &__footer {
    margin-top: 20px;
  }

  &__payment-amount {
    font-size: 48px;
    font-weight: 500;
    width: 100%;
    border: 0;
    outline: none;

    .ant-input-number-input {
      padding: 0;
    }
  }

  &__container {
    @include styled.column;
    justify-content: space-between;
  }

  &__payment-placeholder {
    color: styled.$main;
    font-size: 48px;
  }

  &__cash-container {
    background-color: #f8f7f8;
    padding: 10px;
  }

  &__divider {
    margin: 0;
  }

  &__cash-total {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #f8f7f8;
    padding: 0 20px 30px 20px;

    &--label {
      font-weight: bold;
      color: #9e9e9e;
    }

    &--amount {
      color: #9e9e9e;
      font-weight: bold;
      font-size: 19px;
    }

    &--error {
      color: #ed1a3a;
      font-weight: bold;
      font-size: 19px;
    }
  }
}
