@use '../../../styles/index.scss' as styled;

.DocumentPrefix {
  @include styled.input;

  &__modal {
    @include styled.modal;
    @include styled.labels;
    @include styled.form;
    @include styled.checkbox;
  }

  &__footer {
    padding-top: 24px;
  }
}
