@use '../../styles/index.scss' as styled;

.PreviewFile {
  &__preview-container {
    background-color: styled.$light-gray-background;
    margin-left: 24px;
  }

  &__frame-container {
    box-shadow: -1px -1px 6px 4px #00000024;
  }

  &__basic-icon {
    font-size: large;
    margin-right: 15px;
  }
}
