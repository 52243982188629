.Layout {
  margin: 0 auto;
  display: flex;
  height: 100%;

  &__menu {
    background: #47576b;
  }

  &__menu-expand {
    display: flex;
    justify-content: center;
    margin: 14px 0;
  }

  &__main-content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__page-contents {
    overflow: auto;
    padding: 26px 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &::after {
      content: '';
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      height: 51px;
      background: transparent linear-gradient(180deg, var(--unnamed-color-000000) 0%, #00000000 100%) 0% 0% no-repeat
        padding-box;
      background: transparent linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
      opacity: 0.03;
    }
  }

  &__error {
    justify-content: center;
  }

  &__error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
