@use '../../styles/index.scss' as styled;

.FilingDateModal {
  @include styled.modal;
  @include styled.labels;
  @include styled.date-picker;
  @include styled.form;

  &__footer {
    padding-top: 16px;
  }
}
