@use '../../styles/index.scss' as styled;

.Header {
  padding-left: 40px;
  padding-right: 40px;
  background-color: styled.$white;

  &__container {
    padding-top: 17px;
    padding-bottom: 14px;
  }

  &__avatar {
    background-color: styled.$white;
    border: 1px solid styled.$gray-divider;
    padding: 6px;
    margin-right: 8px;
    cursor: pointer;
  }

  &__search-input {
    width: 300px;
  }

  &__action-menu {
    .ant-dropdown-menu-item:not(:last-child) {
      border-bottom: 1px solid styled.$gray-divider;
    }
  }
}
