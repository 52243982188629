@use '../../../../../../styles/index.scss' as styled;

.SignUpHeaderItem {
  &__title {
    font-size: 23px;
  }

  &__link-icon {
    font-size: 20px;
    color: styled.$red;
  }

  &__link-container {
    cursor: pointer;
  }
}
