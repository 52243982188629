@use '../../styles/index.scss' as styled;

.ChangeFileName {
  @include styled.form;
  @include styled.modal;

  .ant-modal-body {
    padding-top: 12px;
  }
}
