@use '../../styles/index.scss' as styled;

.PreviewFileHeader {
  &__info-container {
    padding: 12px 24px;
  }

  &__info-lable {
    color: styled.$gray-text;
    font-weight: 500;
    margin-right: 5px;
  }

  &__info-description {
    color: styled.$main;
  }
}
