@use '../../styles/index.scss' as styled;

.PageTitle {
  @include styled.row;
  margin-bottom: 8px;

  &__icon {
    font-size: 18px;
    color: styled.$red;
    margin-left: 6px;
    margin-top: 6px;
    cursor: pointer;
  }

  &__link {
    font-size: 16px;
    margin-left: 6px;
    margin-top: 6px;
    cursor: pointer;
  }

  &__link > a {
    color: styled.$red;
  }
}
