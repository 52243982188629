@use '../../../../../../styles/index.scss' as styled;

.SignUpHeader {
  padding-bottom: 40px;

  &__container {
    border: 1px solid styled.$gray-divider;
    padding: 14px 20px;
  }
}
