.InfoBlockPopover {
  cursor: pointer;

  &__item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #dadada;
    font-size: 14px;

    &:last-child {
      border: none;
    }

    i {
      font-size: 18px;
    }
  }

  &__item-inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 6px;
  }

  &__completed {
    color: #54a575;
  }

  &__not-started {
    color: #c4cdd1;
  }

  &__in-progress {
    color: #47576b;
  }

  &__item-title {
    color: #000000;
    margin: 6px;
  }

  &__item-status {
    font-weight: bold;
  }
}

.ant-popover-inner-content {
  padding: 12px 24px;
}
