.ThreeColumnLowerLayout {
  .divider {
    margin: 0 0 10px 0;
  }

  .three_col_row {
    max-height: 860px;
  }
  .left_col,
  .right_col {
    max-height: 860px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #adb5bd;
      border-top: 2px solid transparent;
    }

    &::-webkit-scrollbar-track {
      width: 10px;
      border-radius: 10px;
      background: none;
    }
  }

  .preview_container {
    height: 860px;
  }
}
