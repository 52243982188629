@use './variables' as variables;

@mixin date-picker {
  .ant-picker-large .ant-picker-input > input {
    font-size: 14px;
  }

  .ant-picker-large {
    height: 42px;
    width: 100%;
  }

  .ant-picker-suffix {
    color: variables.$main;
  }
}
