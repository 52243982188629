@use '../../styles/index.scss' as styled;

.LeftMenu {
  &__menu {
    &--first-level {
      position: sticky;
      left: 0;

      .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
      .ant-menu-dark .ant-menu-submenu-selected {
        background-color: styled.$menu-active-tab;
      }

      z-index: 1000;
      background-color: styled.$menu-inactive-tab;
    }

    &--second-level {
      z-index: 999 !important;

      .ant-drawer-body {
        padding: 0;
      }

      .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
        font-weight: bold;
      }
    }
  }
}
