/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.Button_Custom {
  .ant-btn {
    line-height: 40px;
    position: relative;
    display: inline-block;
    font-weight: 600;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    height: 42px;
    padding: 0 23px;
    font-size: 14px;
    border-radius: 0;
    color: #ffffff;
    background: #47576b;
    border-color: #f7f6f7;
  }
  .ant-btn > .anticon {
    line-height: 1;
  }
  .ant-btn,
  .ant-btn:active,
  .ant-btn:focus {
    outline: 0;
  }
  .ant-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }
  .ant-btn[disabled] {
    cursor: not-allowed;
  }
  .ant-btn[disabled] > * {
    pointer-events: none;
  }
  .ant-btn-lg {
    height: 58px;
    padding: 15.4px 23px;
    font-size: 16px;
    border-radius: 0;
  }
  .ant-btn-sm {
    height: 32px;
    padding: 4px 7px;
    font-size: 14px;
    border-radius: 0;
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #fa4359;
    background: #f7f6f7;
    border-color: #fa4359;
  }
  .ant-btn:hover > a:only-child,
  .ant-btn:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn:hover > a:only-child::after,
  .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:active {
    color: #c70c2e;
    background: #f7f6f7;
    border-color: #c70c2e;
  }
  .ant-btn:active > a:only-child {
    color: currentColor;
  }
  .ant-btn:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn[disabled] > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn[disabled] > a:only-child::after,
  .ant-btn[disabled]:hover > a:only-child::after,
  .ant-btn[disabled]:focus > a:only-child::after,
  .ant-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn:hover > a:only-child,
  .ant-btn:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn:hover > a:only-child::after,
  .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:active,
  .ant-btn.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn:active > a:only-child,
  .ant-btn.active > a:only-child {
    color: currentColor;
  }
  .ant-btn:active > a:only-child::after,
  .ant-btn.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn[disabled] > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn[disabled] > a:only-child::after,
  .ant-btn[disabled]:hover > a:only-child::after,
  .ant-btn[disabled]:focus > a:only-child::after,
  .ant-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    text-decoration: none;
    background: #f7f6f7;
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #fa4359;
    background: #f7f6f7;
    border-color: #fa4359;
  }
  .ant-btn:hover > a:only-child,
  .ant-btn:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn:hover > a:only-child::after,
  .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:active {
    color: #c70c2e;
    background: #f7f6f7;
    border-color: #c70c2e;
  }
  .ant-btn:active > a:only-child {
    color: currentColor;
  }
  .ant-btn:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn[disabled] > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn[disabled] > a:only-child::after,
  .ant-btn[disabled]:hover > a:only-child::after,
  .ant-btn[disabled]:focus > a:only-child::after,
  .ant-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn > a:only-child {
    color: currentColor;
  }
  .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #ffffff;
    background: #333e4d;
    border-color: #333e4d;
  }
  .ant-btn:hover > a:only-child,
  .ant-btn:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn:hover > a:only-child::after,
  .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn:active,
  .ant-btn.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn:active > a:only-child,
  .ant-btn.active > a:only-child {
    color: currentColor;
  }
  .ant-btn:active > a:only-child::after,
  .ant-btn.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn[disabled] > a:only-child,
  .ant-btn[disabled]:hover > a:only-child,
  .ant-btn[disabled]:focus > a:only-child,
  .ant-btn[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn[disabled] > a:only-child::after,
  .ant-btn[disabled]:hover > a:only-child::after,
  .ant-btn[disabled]:focus > a:only-child::after,
  .ant-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn > span {
    display: inline-block;
  }
  .ant-btn-primary {
    color: #fff;
    background: #ed1a3b;
    border-color: #ed1a3b;
    text-shadow: 0;
    box-shadow: 0;
  }
  .ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    background: #fa4359;
    border-color: #fa4359;
  }
  .ant-btn-primary:hover > a:only-child,
  .ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:hover > a:only-child::after,
  .ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:active {
    color: #fff;
    background: #c70c2e;
    border-color: #c70c2e;
  }
  .ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-primary[disabled] > a:only-child,
  .ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: #fff;
    background: #c70c2e;
    border-color: #c70c2e;
  }
  .ant-btn-primary:hover > a:only-child,
  .ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:hover > a:only-child::after,
  .ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-primary:active,
  .ant-btn-primary.active {
    color: #fff;
    background: #c70c2e;
    border-color: #c70c2e;
  }
  .ant-btn-primary:active > a:only-child,
  .ant-btn-primary.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-primary:active > a:only-child::after,
  .ant-btn-primary.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #fa4359;
    border-left-color: #fa4359;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #f7f6f7;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #fa4359;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #f7f6f7;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #fa4359;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #f7f6f7;
  }
  .ant-btn-ghost {
    color: #333333;
    background: transparent;
    border-color: #d9d9d9;
  }
  .ant-btn-ghost > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:hover,
  .ant-btn-ghost:focus {
    color: #fa4359;
    background: transparent;
    border-color: #fa4359;
  }
  .ant-btn-ghost:hover > a:only-child,
  .ant-btn-ghost:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:hover > a:only-child::after,
  .ant-btn-ghost:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:active {
    color: #c70c2e;
    background: transparent;
    border-color: #c70c2e;
  }
  .ant-btn-ghost:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost[disabled],
  .ant-btn-ghost[disabled]:hover,
  .ant-btn-ghost[disabled]:focus,
  .ant-btn-ghost[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-ghost[disabled] > a:only-child,
  .ant-btn-ghost[disabled]:hover > a:only-child,
  .ant-btn-ghost[disabled]:focus > a:only-child,
  .ant-btn-ghost[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost[disabled] > a:only-child::after,
  .ant-btn-ghost[disabled]:hover > a:only-child::after,
  .ant-btn-ghost[disabled]:focus > a:only-child::after,
  .ant-btn-ghost[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:hover,
  .ant-btn-ghost:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-ghost:hover > a:only-child,
  .ant-btn-ghost:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:hover > a:only-child::after,
  .ant-btn-ghost:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost:active,
  .ant-btn-ghost.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-ghost:active > a:only-child,
  .ant-btn-ghost.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost:active > a:only-child::after,
  .ant-btn-ghost.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-ghost[disabled],
  .ant-btn-ghost[disabled]:hover,
  .ant-btn-ghost[disabled]:focus,
  .ant-btn-ghost[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-ghost[disabled] > a:only-child,
  .ant-btn-ghost[disabled]:hover > a:only-child,
  .ant-btn-ghost[disabled]:focus > a:only-child,
  .ant-btn-ghost[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-ghost[disabled] > a:only-child::after,
  .ant-btn-ghost[disabled]:hover > a:only-child::after,
  .ant-btn-ghost[disabled]:focus > a:only-child::after,
  .ant-btn-ghost[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed {
    color: #333333;
    background: #f7f6f7;
    border-color: #f7f6f7;
    border-style: dashed;
  }
  .ant-btn-dashed > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    color: #fa4359;
    background: #f7f6f7;
    border-color: #fa4359;
  }
  .ant-btn-dashed:hover > a:only-child,
  .ant-btn-dashed:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:hover > a:only-child::after,
  .ant-btn-dashed:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:active {
    color: #c70c2e;
    background: #f7f6f7;
    border-color: #c70c2e;
  }
  .ant-btn-dashed:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed[disabled],
  .ant-btn-dashed[disabled]:hover,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dashed[disabled] > a:only-child,
  .ant-btn-dashed[disabled]:hover > a:only-child,
  .ant-btn-dashed[disabled]:focus > a:only-child,
  .ant-btn-dashed[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed[disabled] > a:only-child::after,
  .ant-btn-dashed[disabled]:hover > a:only-child::after,
  .ant-btn-dashed[disabled]:focus > a:only-child::after,
  .ant-btn-dashed[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:hover,
  .ant-btn-dashed:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-dashed:hover > a:only-child,
  .ant-btn-dashed:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:hover > a:only-child::after,
  .ant-btn-dashed:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed:active,
  .ant-btn-dashed.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-dashed:active > a:only-child,
  .ant-btn-dashed.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed:active > a:only-child::after,
  .ant-btn-dashed.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dashed[disabled],
  .ant-btn-dashed[disabled]:hover,
  .ant-btn-dashed[disabled]:focus,
  .ant-btn-dashed[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dashed[disabled] > a:only-child,
  .ant-btn-dashed[disabled]:hover > a:only-child,
  .ant-btn-dashed[disabled]:focus > a:only-child,
  .ant-btn-dashed[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dashed[disabled] > a:only-child::after,
  .ant-btn-dashed[disabled]:hover > a:only-child::after,
  .ant-btn-dashed[disabled]:focus > a:only-child::after,
  .ant-btn-dashed[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger {
    color: #fff;
    background: #ad1931;
    border-color: #ad1931;
    text-shadow: 0;
    box-shadow: 0;
  }
  .ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:hover,
  .ant-btn-danger:focus {
    color: #fff;
    background: #ba3849;
    border-color: #ba3849;
  }
  .ant-btn-danger:hover > a:only-child,
  .ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:hover > a:only-child::after,
  .ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:active {
    color: #fff;
    background: #870c25;
    border-color: #870c25;
  }
  .ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger[disabled],
  .ant-btn-danger[disabled]:hover,
  .ant-btn-danger[disabled]:focus,
  .ant-btn-danger[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-danger[disabled] > a:only-child,
  .ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:hover,
  .ant-btn-danger:focus {
    color: #fff;
    background: #870c25;
    border-color: #870c25;
  }
  .ant-btn-danger:hover > a:only-child,
  .ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:hover > a:only-child::after,
  .ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-danger:active,
  .ant-btn-danger.active {
    color: #fff;
    background: #870c25;
    border-color: #870c25;
  }
  .ant-btn-danger:active > a:only-child,
  .ant-btn-danger.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-danger:active > a:only-child::after,
  .ant-btn-danger.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link {
    color: #ed1a3b;
    color: #333333;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #fa4359;
    background: transparent;
    border-color: #fa4359;
  }
  .ant-btn-link:hover > a:only-child,
  .ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:hover > a:only-child::after,
  .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:active {
    color: #c70c2e;
    background: transparent;
    border-color: #c70c2e;
  }
  .ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-link:hover > a:only-child,
  .ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:hover > a:only-child::after,
  .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:active,
  .ant-btn-link.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-link:active > a:only-child,
  .ant-btn-link.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child::after,
  .ant-btn-link.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover {
    background: transparent;
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus,
  .ant-btn-link:active {
    border-color: transparent;
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: #959597;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #fa4359;
    background: transparent;
    border-color: #fa4359;
  }
  .ant-btn-link:hover > a:only-child,
  .ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:hover > a:only-child::after,
  .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:active {
    color: #c70c2e;
    background: transparent;
    border-color: #c70c2e;
  }
  .ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-link:hover > a:only-child,
  .ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:hover > a:only-child::after,
  .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:active,
  .ant-btn-link.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-link:active > a:only-child,
  .ant-btn-link.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link:active > a:only-child::after,
  .ant-btn-link.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus,
  .ant-btn-link:active {
    background-color: transparent;
    border-color: transparent;
    color: #ed1a3b;
  }
  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: #959597;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-link[disabled] > a:only-child,
  .ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text {
    color: #333333;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: #fa4359;
    background: transparent;
    border-color: #fa4359;
  }
  .ant-btn-text:hover > a:only-child,
  .ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:hover > a:only-child::after,
  .ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:active {
    color: #c70c2e;
    background: transparent;
    border-color: #c70c2e;
  }
  .ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:hover,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-text[disabled] > a:only-child,
  .ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-text:hover > a:only-child,
  .ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:hover > a:only-child::after,
  .ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:active,
  .ant-btn-text.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-text:active > a:only-child,
  .ant-btn-text.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text:active > a:only-child::after,
  .ant-btn-text.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:hover,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-text[disabled] > a:only-child,
  .ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    color: #333333;
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }
  .ant-btn-text:active {
    color: #333333;
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
  }
  .ant-btn-text[disabled],
  .ant-btn-text[disabled]:hover,
  .ant-btn-text[disabled]:focus,
  .ant-btn-text[disabled]:active {
    color: #959597;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-text[disabled] > a:only-child,
  .ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous {
    color: #ad1931;
    background: #f7f6f7;
    border-color: #ad1931;
  }
  .ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous:hover,
  .ant-btn-dangerous:focus {
    color: #ba3849;
    background: #f7f6f7;
    border-color: #ba3849;
  }
  .ant-btn-dangerous:hover > a:only-child,
  .ant-btn-dangerous:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:hover > a:only-child::after,
  .ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous:active {
    color: #870c25;
    background: #f7f6f7;
    border-color: #870c25;
  }
  .ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous[disabled],
  .ant-btn-dangerous[disabled]:hover,
  .ant-btn-dangerous[disabled]:focus,
  .ant-btn-dangerous[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous[disabled] > a:only-child,
  .ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous[disabled] > a:only-child::after,
  .ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous:hover,
  .ant-btn-dangerous:focus {
    color: #ba3849;
    background: #f7f6f7;
    border-color: #ba3849;
    background: #6e0f1f;
  }
  .ant-btn-dangerous:hover > a:only-child,
  .ant-btn-dangerous:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:hover > a:only-child::after,
  .ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous:active,
  .ant-btn-dangerous.active {
    color: #870c25;
    background: #f7f6f7;
    border-color: #870c25;
    background: #6e0f1f;
  }
  .ant-btn-dangerous:active > a:only-child,
  .ant-btn-dangerous.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous:active > a:only-child::after,
  .ant-btn-dangerous.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous[disabled],
  .ant-btn-dangerous[disabled]:hover,
  .ant-btn-dangerous[disabled]:focus,
  .ant-btn-dangerous[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous[disabled] > a:only-child,
  .ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous[disabled] > a:only-child::after,
  .ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    background: #ad1931;
    border-color: #ad1931;
    text-shadow: 0;
    box-shadow: 0;
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary:hover,
  .ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    background: #ba3849;
    border-color: #ba3849;
  }
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    background: #870c25;
    border-color: #870c25;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary[disabled],
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary:hover,
  .ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    background: #870c25;
    border-color: #870c25;
  }
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-primary:active,
  .ant-btn-dangerous.ant-btn-primary.active {
    color: #fff;
    background: #870c25;
    border-color: #870c25;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child,
  .ant-btn-dangerous.ant-btn-primary.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-primary.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link {
    color: #ad1931;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-dangerous.ant-btn-link:focus {
    color: #fa4359;
    background: transparent;
    border-color: #fa4359;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:active {
    color: #c70c2e;
    background: transparent;
    border-color: #c70c2e;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-dangerous.ant-btn-link:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:active,
  .ant-btn-dangerous.ant-btn-link.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child,
  .ant-btn-dangerous.ant-btn-link.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-dangerous.ant-btn-link:focus {
    color: #ba3849;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link:active {
    color: #870c25;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: #959597;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text {
    color: #ad1931;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: #fa4359;
    background: transparent;
    border-color: #fa4359;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:active {
    color: #c70c2e;
    background: transparent;
    border-color: #c70c2e;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:active,
  .ant-btn-dangerous.ant-btn-text.active {
    color: #000;
    background: #eaeaea;
    border-color: #eaeaea;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child,
  .ant-btn-dangerous.ant-btn-text.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:hover,
  .ant-btn-dangerous.ant-btn-text:focus {
    color: #ba3849;
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text:active {
    color: #870c25;
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-dangerous.ant-btn-text[disabled],
  .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: #959597;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-icon-only {
    width: 44px;
    height: 44px;
    padding: 8.4px 0;
    font-size: 16px;
    border-radius: 0;
    vertical-align: -1px;
  }
  .ant-btn-icon-only > * {
    font-size: 16px;
  }
  .ant-btn-icon-only.ant-btn-lg {
    width: 58px;
    height: 58px;
    padding: 13.9px 0;
    font-size: 18px;
    border-radius: 0;
  }
  .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 18px;
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 32px;
    height: 32px;
    padding: 4px 0;
    font-size: 14px;
    border-radius: 0;
  }
  .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 14px;
  }
  .ant-btn-round {
    height: 44px;
    padding: 10px 22px;
    font-size: 14px;
    border-radius: 44px;
  }
  .ant-btn-round.ant-btn-lg {
    height: 58px;
    padding: 15.4px 29px;
    font-size: 16px;
    border-radius: 58px;
  }
  .ant-btn-round.ant-btn-sm {
    height: 32px;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 32px;
  }
  .ant-btn-round.ant-btn-icon-only {
    width: auto;
  }
  .ant-btn-circle {
    min-width: 44px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
  }
  .ant-btn-circle.ant-btn-lg {
    min-width: 58px;
    border-radius: 50%;
  }
  .ant-btn-circle.ant-btn-sm {
    min-width: 32px;
    border-radius: 50%;
  }
  .ant-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none;
  }
  .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn .anticon.anticon-plus > svg,
  .ant-btn .anticon.anticon-minus > svg {
    shape-rendering: optimizeSpeed;
  }
  .ant-btn.ant-btn-loading {
    position: relative;
  }
  .ant-btn.ant-btn-loading:not([disabled]) {
    pointer-events: none;
  }
  .ant-btn.ant-btn-loading::before {
    display: block;
  }
  .ant-btn > .ant-btn-loading-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 8px;
    animation: none;
  }
  .ant-btn > .ant-btn-loading-icon .anticon svg {
    animation: loadingCircle 1s infinite linear;
  }
  .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
  }
  .ant-btn-group {
    position: relative;
    display: inline-flex;
  }
  .ant-btn-group > .ant-btn,
  .ant-btn-group > span > .ant-btn {
    position: relative;
  }
  .ant-btn-group > .ant-btn:hover,
  .ant-btn-group > span > .ant-btn:hover,
  .ant-btn-group > .ant-btn:focus,
  .ant-btn-group > span > .ant-btn:focus,
  .ant-btn-group > .ant-btn:active,
  .ant-btn-group > span > .ant-btn:active {
    z-index: 2;
  }
  .ant-btn-group > .ant-btn[disabled],
  .ant-btn-group > span > .ant-btn[disabled] {
    z-index: 0;
  }
  .ant-btn-group .ant-btn-icon-only {
    font-size: 14px;
  }
  .ant-btn-group-lg > .ant-btn,
  .ant-btn-group-lg > span > .ant-btn {
    height: 58px;
    padding: 15.4px 23px;
    font-size: 16px;
    border-radius: 0;
  }
  .ant-btn-group-lg .ant-btn.ant-btn-icon-only {
    width: 58px;
    height: 58px;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-group-sm > .ant-btn,
  .ant-btn-group-sm > span > .ant-btn {
    height: 32px;
    padding: 4px 7px;
    font-size: 14px;
    border-radius: 0;
  }
  .ant-btn-group-sm > .ant-btn > .anticon,
  .ant-btn-group-sm > span > .ant-btn > .anticon {
    font-size: 14px;
  }
  .ant-btn-group-sm .ant-btn.ant-btn-icon-only {
    width: 32px;
    height: 32px;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-group .ant-btn + .ant-btn,
  .ant-btn + .ant-btn-group,
  .ant-btn-group span + .ant-btn,
  .ant-btn-group .ant-btn + span,
  .ant-btn-group > span + span,
  .ant-btn-group + .ant-btn,
  .ant-btn-group + .ant-btn-group {
    margin-left: -1px;
  }
  .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }
  .ant-btn-group .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn:first-child,
  .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0;
  }
  .ant-btn-group > .ant-btn:only-child {
    border-radius: 0;
  }
  .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn-group-sm > .ant-btn:only-child {
    border-radius: 0;
  }
  .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn-group > .ant-btn-group {
    float: left;
  }
  .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-rtl.ant-btn + .ant-btn-group,
  .ant-btn-rtl.ant-btn-group span + .ant-btn,
  .ant-btn-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-rtl.ant-btn-group > span + span,
  .ant-btn-rtl.ant-btn-group + .ant-btn,
  .ant-btn-rtl.ant-btn-group + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ant-btn-group-rtl.ant-btn + .ant-btn-group,
  .ant-btn-group-rtl.ant-btn-group span + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group .ant-btn + span,
  .ant-btn-group-rtl.ant-btn-group > span + span,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn,
  .ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
    margin-right: -1px;
    margin-left: auto;
  }
  .ant-btn-group.ant-btn-group-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-btn:focus > span,
  .ant-btn:active > span {
    position: relative;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 8px;
  }
  .ant-btn-background-ghost {
    color: #fff;
    background: transparent !important;
    border-color: #fff;
  }
  .ant-btn-background-ghost.ant-btn-primary {
    color: #ed1a3b;
    background: transparent;
    border-color: #ed1a3b;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary:hover,
  .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #fa4359;
    background: transparent;
    border-color: #fa4359;
  }
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary:active {
    color: #c70c2e;
    background: transparent;
    border-color: #c70c2e;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger {
    color: #ad1931;
    background: transparent;
    border-color: #ad1931;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger:hover,
  .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #ba3849;
    background: transparent;
    border-color: #ba3849;
  }
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger:active {
    color: #870c25;
    background: transparent;
    border-color: #870c25;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous {
    color: #ad1931;
    background: transparent;
    border-color: #ad1931;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous:hover,
  .ant-btn-background-ghost.ant-btn-dangerous:focus {
    color: #ba3849;
    background: transparent;
    border-color: #ba3849;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #870c25;
    background: transparent;
    border-color: #870c25;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: #ad1931;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
    color: #ba3849;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #870c25;
    background: transparent;
    border-color: transparent;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em;
  }
  .ant-btn-two-chinese-chars > *:not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
  }
  .ant-btn-block {
    width: 100%;
  }
  .ant-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  a.ant-btn {
    padding-top: 0.01px !important;
    line-height: 42px;
  }
  a.ant-btn-lg {
    line-height: 56px;
  }
  a.ant-btn-sm {
    line-height: 30px;
  }
  .ant-btn-rtl {
    direction: rtl;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-right-color: #fa4359;
    border-left-color: #f7f6f7;
  }
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-right-color: #f7f6f7;
    border-left-color: #fa4359;
  }
  .ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0;
    padding-left: 8px;
  }
  .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-btn-rtl.ant-btn > .anticon + span,
  .ant-btn-rtl.ant-btn > span + .anticon {
    margin-right: 8px;
    margin-left: 0;
  }
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  .ant-btn {
    font-family: -apple-system, BlinkMacSystemFont, 'ProximaNova', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
  .ant-btn::after {
    --antd-wave-shadow-color: #333333;
  }
  .ant-btn-rtl .fabric-icon + span {
    margin-right: 8px;
  }
  .ant-btn > i {
    vertical-align: bottom;
  }
  .ant-btn-success {
    color: #fff;
    background: #54a575;
    border-color: #54a575;
    text-shadow: 0;
    box-shadow: 0;
  }
  .ant-btn-success > a:only-child {
    color: currentColor;
  }
  .ant-btn-success > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-success:hover,
  .ant-btn-success:focus {
    color: #fff;
    background: #3b8058;
    border-color: #3b8058;
    background: #346648;
  }
  .ant-btn-success:hover > a:only-child,
  .ant-btn-success:focus > a:only-child {
    color: currentColor;
  }
  .ant-btn-success:hover > a:only-child::after,
  .ant-btn-success:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-success:active,
  .ant-btn-success.active {
    color: #fff;
    background: #3b8058;
    border-color: #3b8058;
    background: #346648;
  }
  .ant-btn-success:active > a:only-child,
  .ant-btn-success.active > a:only-child {
    color: currentColor;
  }
  .ant-btn-success:active > a:only-child::after,
  .ant-btn-success.active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-success[disabled],
  .ant-btn-success[disabled]:hover,
  .ant-btn-success[disabled]:focus,
  .ant-btn-success[disabled]:active {
    color: #959597;
    background: #dadbe0;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn-success[disabled] > a:only-child,
  .ant-btn-success[disabled]:hover > a:only-child,
  .ant-btn-success[disabled]:focus > a:only-child,
  .ant-btn-success[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ant-btn-success[disabled] > a:only-child::after,
  .ant-btn-success[disabled]:hover > a:only-child::after,
  .ant-btn-success[disabled]:focus > a:only-child::after,
  .ant-btn-success[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ant-btn-success::after {
    --antd-wave-shadow-color: #54a575;
  }
  .ant-btn-danger::after {
    --antd-wave-shadow-color: #fff;
  }
  .ant-btn-primary::after {
    --antd-wave-shadow-color: #ed1a3b;
  }
  .ant-btn-icon-only.ant-btn-default {
    width: 44px;
    background-color: transparent;
    border: none;
    color: #333333;
    font-size: 16px;
  }
  .ant-btn-icon-only.ant-btn-default:hover,
  .ant-btn-icon-only.ant-btn-default:active,
  .ant-btn-icon-only.ant-btn-default:focus {
    background-color: #e5e5ea;
    color: #333333;
  }
}
