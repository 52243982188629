$red: #ed1a3b !default;
$red-faded: #c70c2e !default;
$light-red: #fee8e7 !default;
$orange: #fe7a4a !default;
$orange-faded: #fe5416 !default;
$light-gray: #dadada !default;
$gray-text: #9e9e9e !default;
$light-gray-text: #7c7b7c !default;
$gray-divider: #e8e8e8 !default;
$subtab-active-background: #e8e8e8 !default;
$gray-background: #47576b !default;
$gray-background-faded: #333e4d !default;
$gray-background-avatar: #667b91 !default;
$light-gray-background: #f8f7f8 !default;
$disabled-gray-background: #c4cdd1 !default;
$gray-border: #c4cdd1 !default;
$white: #ffffff !default;
$main: #000000d9 !default;
$blue: #3cbeeb !default;
$light-blue: #f3fafd !default;
$special-blue: #14a4d3 !default;
$special-blue-faded: #1080ad !default;
$green: #54a575 !default;
$green-faded: #3b8058 !default;
$box-shadow: #00000024 !default;
$black: #000000 !default;
$focus-blue: #40a9ff !default;
$input-border: #d9d9d9 !default;
$text-disabled: #00000040 !default;
$field-disabled: #f5f5f5 !default;
$bright-text: #ffffff !default;
$dark-text: #000000 !default;
$dark-cell-background: #eaeaea;
$lighter-cell-background: #f1f1f1;
$cell-value-wrap: #434343 !default;
$inactive-tab: #88949e !default;
$dark-blue: #0780b8 !default;
$menu-inactive-tab: #47576b !default;
$menu-active-tab: #2f3a47 !default;
$gray2: #6f6f6f !default;
