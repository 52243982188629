@use '../../styles/index.scss' as styled;

.OverrideModal {
  @include styled.modal;
  @include styled.labels;
  @include styled.form;

  &__footer {
    padding-top: 16px;
  }
}
