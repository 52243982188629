@use './variables' as styled;

@mixin tabs {
  .ant-tabs {
    overflow: inherit;
  }
  .ant-tabs-tab-btn {
    font-weight: bold;
    padding: 0 20px;
  }

  .ant-tabs-ink-bar {
    background: styled.$red;
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 10px 0;
    font-size: 14px;

    &:hover {
      color: styled.$main;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: styled.$red;
  }

  .ant-tabs > .ant-tabs-nav {
    align-content: flex-end;
  }

  .ant-tabs-extra-content {
    padding-bottom: 2px;
  }
}

@mixin tabs-gray {
  @include tabs;

  .ant-tabs-tab-btn {
    color: styled.$gray-text;
    padding: 0 10px;
  }
}

@mixin tabs-title-lg {
  .ant-tabs-tab {
    font-size: 16px;
  }
}
