@use '../../styles/variables' as cssVars;

.NoticeToUser {
  border: 2px solid cssVars.$green;
  padding: 14px;
  margin-bottom: 20px;

  [class~='title'] {
    font-weight: bold;
    padding-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0.5em;
  }

  p.info_message {
    font-style: italic;
    font-weight: 500;
  }
}
