@use '../../../../../../styles/index.scss' as styled;

.ChecklistModal {
  @include styled.modal;
  @include styled.form;
  @include styled.select;
  @include styled.select;

  &__footer {
    padding-top: 24px;
  }

  &__list {
    max-height: 500px;
    overflow-y: auto;
    border-bottom: 1px solid styled.$gray-border;
    border-top: 1px solid styled.$gray-border;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &__filing-type {
    .ant-form-item-control-input-content {
      line-height: 42px;
    }
  }

  &__list-item {
    border-bottom: 1px dotted styled.$gray-border;
    padding: 12px 0;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item-label > label {
    height: 42px;
    margin-right: 10px;
  }
}
