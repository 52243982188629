@use '../../../../styles/index.scss' as index;

.EmailView {
  .ant-divider-horizontal {
    border-width: 1px !important;
  }

  &__spinner {
    @include index.row-centered;
    margin-top: 100px;
  }

  &__inner-blocks-container {
    @include index.row;
  }

  &__body {
    padding: 20px 0;
  }

  &__buttons-container {
    @include index.row-centered;
    gap: 12px;
  }
}
