@use '../../../styles/index.scss' as styled;

.ReceiptTable {
  @include styled.table;

  &__title-container {
    margin-top: 25px;
    @include styled.row-spaced;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
  }

  &__table {
    margin-top: 25px;
  }

  &__table-empty {
    @include styled.table-empty;
  }

  &__add-receipt-btn {
    background-color: styled.$red;
    color: styled.$white;

    &:hover,
    &:active {
      background-color: styled.$light-gray;
      color: styled.$red;
    }
  }

  &__cell-content {
    display: flex;
    align-items: center;

    i {
      font-size: 18px;
      color: styled.$red;
      margin-left: 2px;
    }
  }

  &__title-inner-rightside-container {
    @include styled.row;
    justify-content: flex-end;
  }

  &__entries-count {
    margin-right: 20px;
    span {
      font-weight: bold;
    }
  }

  &__action-dropdown-icon {
    cursor: pointer;
    font-size: 18px;
  }

  &__action-icon {
    cursor: pointer;
    color: styled.$red;
  }

  &__action-name {
    margin-left: 8px;
  }
}
